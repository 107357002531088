import { isIOS } from './../../hooks/isIOS';

let descReadTimeout, utterance, speakTimeout, shouldPauseVideo;
let descriptionArray = [];
let i = 0;
class AudioDescriptionsClass {
  static STATUSES = {
    INITIALIZING: 'initializing',
    PAUSED: 'paused',
    PENDING: 'pending',
    SPEAKING: 'speaking',
  };

  //Private Props
  #paused = true;
  #pending = false;
  #speaking = false;

  constructor(rate) {
    this.isUsingSpeechAPI = SpeechSynthesisUtterance !== undefined;
    this.locale = new URLSearchParams(window.location.search).get('locale') || 'en-US';
    this.lastDesc = null;
    this.speechRate = rate || 120;

    if (this.isUsingSpeechAPI) {
      this.speechMethod = window.speechSynthesis;
      this.speechMethod.lang = this.locale;

      if (isIOS()) {
        const voices = this.speechMethod.getVoices();
        // Removing Eloquence Voices which are causing troubles - as preferable select compact or super-compact if available
        const removeiOSEloquenceVoices = voices.find(
          (el) => !el.voiceURI.startsWith('com.apple.eloquence') && el.voiceURI.includes('compact') && el.lang === this.locale
        );
        this.voice = removeiOSEloquenceVoices !== null ? removeiOSEloquenceVoices : voices.find((voice) => voice.lang === this.locale);
        if (!this.voice) {
          this.voice = voices.find((voice) => voice.lang.split('-')[0] === this.locale.split('-')[0]);
          if (!this.voice) {
            console.error('No language found for speech synthesis, getting defaut language');
            this.voice = voices[0];
          }
        }
      } else {
        this.speechMethod.addEventListener('voiceschanged', () => {
          const voices = this.speechMethod.getVoices();
          this.voice = voices.find((voice) => voice.lang === this.locale);
          if (!this.voice) {
            this.voice = voices.find((voice) => voice.lang.split('-')[0] === this.locale.split('-')[0]);
            if (!this.voice) {
              console.error('No language found for speech synthesis, getting defaut language');
              this.voice = voices[0];
            }
          }
        });
      }
    } else {
      this.speechMethod = document.createElement('div');
      this.speechMethod.setAttribute('aria-live', 'assertive');
      this.speechMethod.style.position = 'absolute';
      this.speechMethod.style.opacity = 0;
      this.speechMethod.style.pointerEvents = 'none';
      document.body.appendChild(this.speechMethod);
    }
    AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.INITIALIZING;
    dispatchEvent(AudioDescriptionEvent);
  }

  get paused() {
    let status;
    if (this.isUsingSpeechAPI) {
      status = this.#paused = this.speechMethod.paused;
    }
    return status;
  }

  get speaking() {
    let status;
    if (this.isUsingSpeechAPI) {
      status = this.#speaking = this.speechMethod.speaking;
    }
    return status;
  }

  get pending() {
    let status;
    if (this.isUsingSpeechAPI) {
      status = this.#pending = this.speechMethod.pending;
    }
    return status;
  }

  resetSpeech() {
    if (utterance) {
      if(this.paused) {
        const event = new Event('end', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        utterance.dispatchEvent(event);
      } else {
        this.speechMethod.cancel();
      }
    }

    AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.PAUSED;
    AudioDescriptionEvent.detail.pauseVideo = false;
    console.log("PAUSEVIDEO = false")
    dispatchEvent(AudioDescriptionEvent);
    if (descReadTimeout) clearTimeout(descReadTimeout);
    utterance = null;
  }

  speak(text, shouldDispatchEvents = true, cancelLastText = true) {

    if (this.isUsingSpeechAPI) {
      //clearing our prior utterance if paused
      if (utterance) {
        if(this.paused) {
          const event = new Event('end', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          utterance.dispatchEvent(event);
        } else {
          this.speechMethod.cancel();
        }
      }
      utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = this.voice;

      if (shouldDispatchEvents) {

        utterance.onstart = (evt) => {
          console.log('utterance started: ' + utterance?.text);
          AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.SPEAKING;
           AudioDescriptionEvent.detail.pauseVideo = shouldPauseVideo;
          if (shouldDispatchEvents) dispatchEvent(AudioDescriptionEvent);
        };

        utterance.onend = (evt) => {
          console.log('utterance ended: ' + utterance?.text);
          AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.PAUSED;
          AudioDescriptionEvent.detail.pauseVideo = false;
          console.log("PAUSEVIDEO = false")
          if (shouldDispatchEvents) dispatchEvent(AudioDescriptionEvent);
          if (descReadTimeout) clearTimeout(descReadTimeout);
          delete evt.target;
          utterance = null;
          // if (!this.pending) window.dispatchEvent(new CustomEvent(PlayCanvasEvents.A11yEvents.Resume, {}));
        };

        /*utterance.onerror = (evt) => {
          //alert(`Error`);//`An error has occurred with the speech synthesis: ${evt.error}`);
          AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.PAUSED;
          AudioDescriptionEvent.detail.pauseVideo = false;
          console.log("PAUSEVIDEO = false")
          if (shouldDispatchEvents) dispatchEvent(AudioDescriptionEvent);
          if (descReadTimeout) clearTimeout(descReadTimeout);
          delete evt.target;
          utterance = null;
        };

        utterance.onpause = (evt) => {
          //alert('Paused');
          AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.PAUSED;
          AudioDescriptionEvent.detail.pauseVideo = false;
          console.log("PAUSEVIDEO = false")
          if (shouldDispatchEvents) dispatchEvent(AudioDescriptionEvent);
          if (descReadTimeout) clearTimeout(descReadTimeout);
          delete evt.target;
          utterance = null;
        }*/
      }
      //this.speechMethod.cancel();
      let self = this;
      speakTimeout = setTimeout(function() {
        self.speechMethod.speak(utterance);
        clearTimeout(speakTimeout);
      }, 250);

    } else {
      AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.SPEAKING;
      if (shouldDispatchEvents) dispatchEvent(AudioDescriptionEvent);
      this.speechMethod.innerHTML = text;
    }

    console.log(`Audio Description: ${text}`);
  }

  readDescription(description) {
    // in here i initially thought of passing in readDescription(description, isPriority)
    // so isPriority would have something similar to this, that will pass audioDescriptions on/off into current index;
    // if (isPriority) {
    // descriptionArray.splice(i, 0, description); // could try unshift to get index 0, it doesnt matter atm ...
    // i++;
    // } else if ....
    //but then isPriority would not be false all the time for useDescriptionsCueChange, because it uses useCallback, so it's not that fast in updating ... and therefore doing like !isPriority for other if statements in our code, doesn't make sense.
    if (description instanceof TextTrackCue) {
      if (description.text.length && description.text !== this.lastDesc) {
        this.lastDesc = description.text; // to avoid repeated description from being read
        const wordCounter = description.text.split(' ');
        const descDuration = wordCounter.length * (60 / this.speechRate);
        shouldPauseVideo = descDuration >= description.endTime - description.startTime;

        AudioDescriptionEvent.detail.pauseVideo = shouldPauseVideo;
        console.log("PAUSEVIDEO = " + shouldPauseVideo)
        if (shouldPauseVideo) {
         if (!this.isUsingSpeechAPI) {
            descReadTimeout = setTimeout(() => {
              console.log('timeout: reading pause ended');
              clearTimeout(descReadTimeout);
              AudioDescriptionEvent.detail.status = AudioDescriptionsClass.STATUSES.PAUSED;
              AudioDescriptionEvent.detail.pauseVideo = false;
              dispatchEvent(AudioDescriptionEvent);
            }, descDuration * 1000);
          }
        }
        
        this.speak(description.text);
        //descriptionArray.push(description.text);
      }
    } else if (typeof description === 'string') {
      this.speak(description);
      //descriptionArray.push(description);
    }
    /*console.log(descriptionArray, i);
    this.speak(descriptionArray[i]);
    i++;
    console.log(i);*/
    //FORCING UPDATE
  }

  cancel() {

    if (this.isUsingSpeechAPI) {
      this.speechMethod.cancel();
    } else {
      this.speechMethod.text = '';
      clearTimeout(descReadTimeout);
    }
    console.log("EVENT: AD: Cancel", utterance, this.speechMethod)
  }

  pause() {

    if (this.isUsingSpeechAPI) {
      this.speechMethod.pause();
    } else {
      this.speechMethod.text = '';
      clearTimeout(descReadTimeout);
    }
    console.log("EVENT: AD: Pause", utterance, this.speechMethod)
  }

  resume() {

    if (this.isUsingSpeechAPI) {
      this.speechMethod.resume();
    } else {
      this.speechMethod.text = '';
      clearTimeout(descReadTimeout);
    }
    console.log("EVENT: AD: Resume", utterance, this.speechMethod)
  }
}

export let AudioDescriptionEvent = new CustomEvent('audioDescriptionEvent', {
  detail: {
    status: null,
    description: null,
    cue: null,
    pauseVideo: true,
  },
});

export const AudioDescriptions = new AudioDescriptionsClass();
