const SvgAccessibility = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 133"
    {...props}
  >
    <g className="container" filter="url(#a)">
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        fill="url(#b)"
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="#fff"
        strokeWidth={3}
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="url(#c)"
        strokeOpacity={0.8}
        strokeWidth={3}
      />
    </g>
    <g className="icon">
      <path fill="url(#d)" d="M76.5,92.7c-1.4,0-2.2-1.1-2.8-2.2c-2.1-3.7-3.8-7.5-4.9-11.6c-0.4-1.4-0.7-3.3-2.8-3.3c-2.1-0.1-2.4,1.7-2.8,3.2
	c-1.2,4.1-2.8,7.9-4.9,11.6c-0.9,1.5-2,3-4,2c-2.1-1.1-1.9-2.9-0.9-4.6c4-7.2,5.2-14.9,5.1-23c-0.1-5-0.6-5.7-5.3-7.2
	c-1.8-0.6-3.7-1.2-5.4-1.9c-1.4-0.6-2.6-1.6-1.9-3.3c0.7-1.6,2-1.9,3.6-1.2c11,4.3,22,4.8,33-0.1c1.4-0.7,2.7-0.3,3.4,1.1
	c0.9,1.7-0.2,2.8-1.7,3.5c-2.1,0.9-4.3,1.7-6.4,2.3c-3.3,0.8-4.5,2.8-4.5,6c-0.1,8.2,0.9,16.1,4.9,23.4C79.8,90.2,79,92.6,76.5,92.7
	z M71.7,46.4c0,3.1-2.5,5.7-5.6,5.9c-3.3,0.1-6-2.7-5.9-5.9c0.1-3.1,2.7-5.6,5.8-5.6C69.1,40.6,71.7,43.2,71.7,46.4z"/>
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={66}
        y1={2}
        x2={66}
        y2={123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191925" />
        <stop offset={1} stopColor="#251E2B" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={66}
        y1={155}
        x2={66}
        y2={46}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={36}
        y1={57.5}
        x2={36}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={66}
        y1={57.5}
        x2={66}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={96}
        y1={57.5}
        x2={96}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <filter
        id="a"
        x={0}
        y={0}
        width={132}
        height={133}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_227_79261"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_227_79261"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgAccessibility;
