import "./PauseGeneral.css";
import CircleButton from "../../components/CircleButton";
import { useStore } from "@tmsw/powerups.store";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import { useLocalizedImg } from "@tmsw/powerups.intl.hooks.use-localized-img";
import { ReactComponent as PausedIcon } from "./paused_icon.svg";
// import SettingsPanel from 'components/SettingsPanel';
import { useEffect } from "react";
import GAME_PAGES from "../../constants/GAME_PAGES";
import CTAButton from "../../components/CTAButton/CTAButton";
import { SettingsDialog } from "@tmsw/powerups.ui.dialogs.settings";
import useResetFocus from "../../hooks/useResetFocus";

export const PauseGeneral = ({ tabBack }) => {
  const translate = useTranslate();
  const page = useStore((state) => state.currentPage);
  const parentNode = "PAUSE_MENU";
  const pageData = useStore((state) => state.localeConfig["PAUSE_MENU"]);
  const bgImg = translate("backgrounds", "GENERAL").main;
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const inPortrait = useStore((state) => state.inPortrait);
  const setIsPauseEnabled = useStore((state) => state.setIsPauseEnabled);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const h1 = translate("h1", parentNode);
  const pageTitle = translate("pageTitle1", parentNode);
  const setPageTitle = useStore((state) => state.setPageTitle);

  useEffect(() => {
    setPageTitle(translate("pageTitle1", "PAUSE_MENU"));
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  useResetFocus([showSettingsPanel, inPortrait, isPauseEnabled]);

  useEffect(() => {
    setIsPauseEnabled(true);
    setPageTitle(pageTitle);
    window.dispatchEvent(
      new CustomEvent("pausePC", {
        detail: {
          // showPauseButton: false,
          isPauseEnabled: true,
        },
      })
    );
    document.activeElement.blur();
    document.getElementById("settingsButton")?.focus();
  }, []);

  return (
    <section
      className='pause-general'
      role='dialog'
      aria-modal='true'
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100rem",
        width: "100%",
        // zIndex: 1000,
        backgroundImage: `url(${process.env.PUBLIC_URL}${bgImg})`,
        backgroundColor: "#021E89",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* adding duplicate settingsPanelToggler for accssibility (following MS) */}
      {/* <div className="settings-toggler-container">
        <SettingsDialog />
      </div> */}
      <div className='pause-menu-content'>
        {/* <nav>{props.children}</nav> */}
        <div className='pause-icon-container'>
          {/* <PausedIcon className='pause-icon' /> */}
          <div className='pause-icon' />
          <h1 className='pauseH1' aria-label={h1}>
            {h1}
          </h1>
        </div>
        <div className='pause-btn-list'>
          <CTAButton
            className='cta-btn--image'
            primary
            onClick={(evt) => {
              tabBack();
            }}
            intlKey={"resumeButton"}
            parentNode={parentNode}
          />
        </div>
      </div>
    </section>
  );
};
