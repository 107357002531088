import { useEffect, useRef, useCallback, useState} from "react";

/**
 * A hook that resets focus back to the settings button on component mount
 * @param {any[]} dependencies 
 */
function useResetFocus(dependencies = []) {
  const originalDependencyLengthRef = useRef(dependencies.length);
  const [visibilityState, setVisibilityState] = useState(true);

  if (originalDependencyLengthRef.current !== dependencies.length) {
    console.error("Must use a static number of dependencies");
  }
  // const handleVisibilityChange = useCallback(() => {
  //   setVisibilityState(document.visibilityState === 'visible');
  // }, []);
  // useEffect(() => {
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  //   return visibilityState;
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      document.activeElement.blur();
      document.getElementById('SettingsPanel-Toggler')?.focus();
    }, 200);
  }, []);
  useEffect(() => {
    
    setTimeout(() => {
      document.activeElement.blur();
      document.getElementById('SettingsPanel-Toggler')?.focus();
      document.getElementById('SettingsPanel-Toggler-Pause')?.focus();
      let settingsButton = document.getElementById('SettingsPanel-Toggler')?.focus()

      if (!settingsButton) {
        settingsButton = document.getElementById('SettingsPanel-Toggler-Pause')?.focus();
      }
    if (settingsButton) {
      document.activeElement.blur();
      settingsButton.focus();
      // settingsButton.setAttribute('tabindex', '1');
      // settingsButton.tabIndex = 1;
      // settingsButton.disabled = false;
      // settingsButton.style.visibility = 'visible';
      // settingsButton.setAttribute('autofocus', 'true')
    } else {
      // console.warn("Could not find the settings button to change focus");
    }}, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useResetFocus;