import { useStore } from "@tmsw/powerups.store";
import { useCallback } from "react";

function useComputeRank() {
  const movesCount = useStore(state => state.movesCount);
  function computeRank() {
    if (movesCount <= 26) {
      return ["success_03_stars_animation","successLevel3",movesCount];
    } else if (movesCount <= 37) {
      return ["success_02_stars_animation","successLevel2",movesCount];
    } else {
      return ["success_01_stars_animation","successLevel1",movesCount];
    }
  }

  return useCallback(computeRank, [movesCount]);
}

export default useComputeRank;