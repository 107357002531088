import { ReactNode } from 'react';

export const BaseFontSize = (props: { children: ReactNode }) => {
  console.log('BaseFontSize: 1rem = 1svh + browser text zoom');

  // Hack to prevent IOS from randomly triggering resize events 
  let isIpad = ((navigator.userAgent.includes("Mac") && "ontouchend" in document) || (navigator.userAgent.includes("iPad")) && navigator.userAgent.includes("Safari")) ? true : false;
  const delay = (isIpad) ? 500 : 50;

  let loop = setTimeout(function() {}, delay);
  let aspectRatioString = '';
  let aspectRatioDecimal = '';
  let managedSVH = 0;
  let wasHidden = false;

  // Enforce the viewport's aspect ratio on wrapper element
  console.log('BaseFontSize: ratio: ', window.innerWidth + ' / ' + window.innerHeight);
  const pageWrapper = document.getElementById('root');
  
  const applyRatio = function() {

    aspectRatioString = window.innerWidth + ' / ' + window.innerHeight;
    aspectRatioDecimal = '' + window.innerWidth / window.innerHeight;

    if (pageWrapper) {
      
      pageWrapper.style.width = 'auto'; 
      pageWrapper.style.position = 'absolute';
      pageWrapper.style.overflow = 'auto';
      const svhCheck = document.createElement('div');
      svhCheck.style.width = '100svw';
      svhCheck.style.height = '100svh';
    
      svhCheck.id = 'svhCheck';
      document.getElementsByTagName('body')[0].appendChild(svhCheck);
      console.log('svhCheck: ', svhCheck.offsetHeight);

      // Assume first render 
      if (managedSVH === 0) {
        managedSVH = svhCheck.offsetHeight;
      }

      // Assume portrait
      // @ts-ignore
      if (window.innerWidth < window.innerHeight) {
      // if (window.innerWidth < window.innerHeight || parseFloat(document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload')) > 1) {
        console.log(`window.innerWidth < window.innerHeight || parseFloat(document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload')) > 1`);
        pageWrapper.style.aspectRatio = aspectRatioString;
        pageWrapper.style.height = '100rem';

        // @ts-ignore
        // pageWrapper.style.width = (parseFloat(document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload')) > 1) ? `${100 * parseFloat(aspectRatioDecimal)}rem` : 'auto';
        if (document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload')) {
          const baseAspectRatioAsDecimal = document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload') ? document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload') : '';
          // @ts-ignore
          const aspectRatioVariance = Math.abs(parseFloat(baseAspectRatioAsDecimal) - parseFloat(aspectRatioDecimal));
          console.log('aspectRatioVariance: ', aspectRatioVariance);
          if (aspectRatioVariance > 0.01) {
            managedSVH = svhCheck.offsetHeight;
          } else {
               // @ts-ignore
            managedSVH = parseFloat(document.getElementsByTagName('html')[0].getAttribute('data-base-font-size-onload')) * 100;
          }
        }
        console.log('base-aspect-ratioAsDecimal-onload: ', document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload'));

      } else {

        // Assume landscape or square – use svw instead
        // @ts-ignore
        (parseFloat(document.getElementsByTagName('html')[0].getAttribute('data-base-aspect-ratioAsDecimal-onload')) > 1) && (managedSVH = svhCheck.offsetWidth);
        pageWrapper.style.aspectRatio = 'unset';
        pageWrapper.style.width = '100%';
        pageWrapper.style.height = '100%';

      }

      svhCheck.remove();

    }
  
    // Assert that the document's default font size (in pixels) is 1% of smallest viewport height
    console.log('BaseFontSize: managedSVH: ', managedSVH);
    document.getElementsByTagName('html')[0].setAttribute('style', `font-size: ${(managedSVH * 0.01)}px;`);
    if (typeof document.getElementsByTagName('html')[0].getAttribute('data-base-font-size-onload') !== 'string') {
      document.getElementsByTagName('html')[0].setAttribute('data-base-font-size-onload', (managedSVH * 0.01).toPrecision(3));
      document.getElementsByTagName('html')[0].setAttribute('data-base-aspect-ratio-onload', aspectRatioString);
      document.getElementsByTagName('html')[0].setAttribute('data-base-aspect-ratioAsDecimal-onload', aspectRatioDecimal);
      document.getElementsByTagName('html')[0].setAttribute('data-base-width-onload', `${window.innerWidth}`);
    }
    
  };

  applyRatio();

  window.addEventListener('resize', (event) => {
    console.log('BaseFontSize: window resized');
    clearTimeout(loop);
    loop = setTimeout(applyRatio, delay);
  });
  

  // Enforce 1rem to be equal to ${managedSVH} for children of <body>
  document.getElementsByTagName('body')[0].setAttribute('style', `font-size: 1rem;`);

  // @2D0: We are not as of yet using this info as browser support is inconsistent
  // const userZoom = window.visualViewport.scale;
  // console.log('BaseFontSize: userZoom: ', userZoom);

  return props.children;
};

// v.0.0.4