import { useEffect, useRef, useState } from "react";
import CircleButton from "../CircleButton";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import { useStore } from "@tmsw/powerups.store";
import GAME_PAGES from "../../constants/GAME_PAGES";
import AudioDescriptions from "../AudioDescriptions";

export const PauseButton = (props) => {
  const translate = useTranslate();
  const isPlaying = useStore((state) => state.isPlaying);
  const play = useStore((state) => state.play);
  const setPlay = useStore((state) => state.setPlay);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const setIsPauseEnabled = useStore((state) => state.setIsPauseEnabled);
  const page = useStore((state) => state.currentPage);
  const [isInFocus, setIsInFocus] = useState(false);
  const pauseBtn = useRef();
  const showPauseBtnForPC = useStore((state) => state.showPauseBtnForPC);
  const componentKey = useStore((state) => state.movesCount);
  const currentCueMetadata = useStore((state) => state.currentCueMetadata);
  const [hidePauseBtnAtAll, setHidePauseBtnAtAll] = useState(false);
  const [hidePauseBtnOnGameMenu, setHidePauseBtnOnGameMenu] = useState(true);

  const styles = {
    zIndex: "90",
    padding: 0,
    display: "block",
  };

  useEffect(() => {
    //if((currentCueMetadata?.id) == "IDLE_LOOP") {
    setHidePauseBtnAtAll(
      currentCueMetadata?.id === "CHAPTER_HOLD" ||
        currentCueMetadata?.id === "CHAPTER_INTRO-OUT"
    );

    //} else
  }, [currentCueMetadata]);

  //   // Check if the cue is of the form select_xx
  //   const isLevelSelectedCue = currentCueMetadata?.id?.match(/select_\d\d/);
  //   // Only show the pause button when the level has been selected for game menu
  //   if (page === GAME_PAGES.LEVEL_SELECTION) {
  //     if (!hidePauseBtnOnGameMenu && !isLevelSelectedCue) {
  //       setHidePauseBtnOnGameMenu(true);
  //     } else if (hidePauseBtnOnGameMenu && isLevelSelectedCue) {
  //       setHidePauseBtnOnGameMenu(false);
  //     }
  //   }
  // }, [currentCueMetadata]);

  useEffect(() => {
    if (
      !isPauseEnabled &&
      !isPlaying &&
      isInFocus &&
      page !== GAME_PAGES.GAME_PLAY
    )
      document.querySelector(".vie-button")?.focus(); // move focus to the first emerging VIE button when pause button disappears
  }, [isPauseEnabled, isPlaying, isInFocus]);

  return (
    ((page === GAME_PAGES.SPLASH && !hidePauseBtnAtAll) ||
      page === GAME_PAGES.GAME_PLAY) &&
    componentKey !== 2 &&
    (isPauseEnabled ||
      isPlaying ||
      (!isPlaying &&
        !AudioDescriptions.speaking &&
        page === GAME_PAGES.GAME_PLAY)) && (
      // this line was added to enable pause button to be visible despide of VIE not playing - looping bug
      <CircleButton
        variant='dark'
        ref={pauseBtn}
        onFocus={() => {
          setIsInFocus(true);
        }}
        onBlur={() => {
          setIsInFocus(false);
        }}
        icon={!isPauseEnabled ? "pause" : "play"}
        style={styles}
        ariaLabel={
          translate(!isPauseEnabled ? "pauseButton" : "resumeButton", "GENERAL")
            .ariaLabel
        }
        onClick={() => {
          setIsPauseEnabled(true);
          // set to true when video is paused
          setPlay(!play);
        }}
      />
    )
  );
};

export default PauseButton;
