// import { ChangeEventHandler } from "react";
import { ChangeEvent, MouseEvent } from "react";
import { useStore } from "@tmsw/powerups.store";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { ToggleSwitch } from "@tmsw/powerups.ui.form.toggle-switch";
import "./Accessibility.css";

export type A11yMenuProps = {
  onChangeCaptions: (
    nativeEvent: MouseEvent<HTMLDivElement, MouseEvent> | Event
  ) => void;
  onChangeDescriptions: (
    nativeEvent: ChangeEvent<HTMLInputElement> | Event
  ) => void;
};

export function A11yMenu({
  onChangeCaptions,
  onChangeDescriptions,
}: A11yMenuProps) {
  const { __t } = useTranslation();
  const captionsEnabled = useStore((state) => state.captionsEnabled);
  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  const setCaptionsEnabled = useStore((state) => state.setCaptionsEnabled);
  const setDescriptionsEnabled = useStore(
    (state) => state.setDescriptionsEnabled
  );
  const localeConfig = __t("VIE");
  const a11yMenuConfig = __t("A11Y_MENU");
  const toyOnScreenLayout = useStore((state) => state.toyOnScreenLayout);

  return (
    <section className='SettingsPanel-Container' id='A11y-Menu' key='a11y_menu'>
      <h1 className='HeaderH1'>{a11yMenuConfig.header}</h1>
      <h2 className='HeaderH2'>{a11yMenuConfig.subHeader1}</h2>
      <p className='Paragraph'>{a11yMenuConfig.bodyCopy1}</p>
      <h2 className='HeaderH2'>{a11yMenuConfig.subHeader2}</h2>
      <p className='Paragraph'>{a11yMenuConfig.bodyCopy2}</p>
      <ToggleSwitch
        onChange={(evt) => {
          const { nativeEvent } = evt;
          setDescriptionsEnabled(evt.target.checked);
          return onChangeCaptions(
            nativeEvent as MouseEvent<HTMLDivElement, MouseEvent> | Event
          );
        }}
        checked={descriptionsEnabled}
        aria-label={localeConfig.ENABLE_AUDIO_DESCRIPTIONS_ARIA}
        tabIndex={0}
      >
        {localeConfig.ENABLE_AUDIO_DESCRIPTIONS}
      </ToggleSwitch>
      <h2 className='HeaderH2'>{a11yMenuConfig.subHeader3}</h2>
      <p className='Paragraph'>{a11yMenuConfig.bodyCopy3}</p>
      <ToggleSwitch
        onChange={(evt) => {
          const { nativeEvent } = evt;
          setCaptionsEnabled(evt.target.checked);
          return onChangeCaptions(
            nativeEvent as MouseEvent<HTMLDivElement, MouseEvent> | Event
          );
        }}
        checked={captionsEnabled}
        aria-label={localeConfig.ENABLE_CAPTIONS_ARIA}
        tabIndex={0}
      >
        {localeConfig.ENABLE_CAPTIONS}
      </ToggleSwitch>

      <h2 className='HeaderH2'>{a11yMenuConfig.subHeader4}</h2>
      <p className='Paragraph'>{a11yMenuConfig.bodyCopy4}</p>
      <h2 className='HeaderH2'>{a11yMenuConfig.subHeader5}</h2>
      <p className='Paragraph'>{a11yMenuConfig.bodyCopy5}</p>
    </section>
  );
}

A11yMenu.defaultProps = {
  onChangeCaptions: () => {},
  onChangeDescriptions: () => {},
};
