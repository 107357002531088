const SvgPlay = (props) => (
	<svg width="170px" height="170px" viewBox="0 0 170 170" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Atom/Button/Play" transform="translate(6.000000, 6.000000)">
            <g id="Group-9" transform="translate(-0.000000, 0.000000)">
                <path d="M79.313,0 C123.116,0 158.626,35.569 158.626,79.445 C158.626,123.321 123.116,158.889 79.313,158.889 C35.51,158.889 0,123.321 0,79.445 C0,35.569 35.51,0 79.313,0" id="Fill-1" fill="#FFFFFF"></path>
                <path d="M79.313,0 C123.116,0 158.626,35.569 158.626,79.445 C158.626,123.321 123.116,158.889 79.313,158.889 C35.51,158.889 0,123.321 0,79.445 C0,35.569 35.51,0 79.313,0 Z" id="Stroke-3" stroke="#0A3B81" strokeWidth="6" className="icon-color"></path>
                <path d="M118.204526,77.0015658 L59.3427758,45.6712813 C55.7020432,43.7323119 50.2701,45.4372677 50.2701,48.5177906 L50.2701,111.179343 C50.2701,114.259866 55.7020432,115.963838 59.3427758,114.025852 L118.204526,82.6955677 C120.958625,81.2295417 120.958625,78.4675918 118.204526,77.0015658" id="Fill-5" fill="#0A3B81" className="icon-color"></path>
            </g>
        </g>
    </g>
</svg>
);

export default SvgPlay;
