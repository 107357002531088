import { fetchDedupe } from 'fetch-dedupe';

const getAnimationData = (url) => {
  console.log('getAnimationData', url);
  return new Promise((resolve, reject) => {
    fetchDedupe(url,  { method: 'GET' })
      .then((res) => resolve(res.data))
      .catch((e) => reject(e))
  });
}

export default getAnimationData;