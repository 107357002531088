import { useEffect } from "react";
import { useStore } from "@tmsw/powerups.store";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import { CTAButton } from "@tmsw/powerups.ui.buttons.cta-button";
import { useLocalizedImg } from "@tmsw/powerups.intl.hooks.use-localized-img";
import useResetFocus from "../../hooks/useResetFocus";
// import logoPng from './img/Logo.png';
// import bgImg from './img/bg.png';
import "./Disclaimer.css";

export const Disclaimer = ({ pageData, goToNextPage }) => {
  const parentNode = "DISCLAIMER";
  const translate = useTranslate();
  const { __t } = useTranslation("DISCLAIMER");
  const setSkipTarget = useStore((state) => state.setSkipTarget);
  const setBackTarget = useStore((state) => state.setBackTarget);
  // const locale = useStore((state) => state.locale);
  const getGlobalImageUrl = (imageName) =>
    `${process.env.PUBLIC_URL}/static/media/global/img/${imageName}`;
  const logoPng = useLocalizedImg(__t("logoImg"), "img");
  const bgImg = getGlobalImageUrl("background.jpg");
  const hmButton = translate("hmButton", parentNode);
  const pageTitle = translate("pageTitle", parentNode);
  const setPageTitle = useStore((state) => state.setPageTitle);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const inPortrait = useStore((state) => state.inPortrait);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  useResetFocus();

  useEffect(() => {
    setBackTarget(null);
    setSkipTarget(null);
  }, [setBackTarget, setSkipTarget]);

  return (
    <section
      className='disclaimer-section'
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundColor: "#021e89",
      }}
    >
      <div className='disclaimer-content'>
        <img
          src={logoPng}
          alt={__t("logoImgAlt")}
          className='disclaimer-logo-img'
        />
        <div className='text-container'>
          <h1 className='HeaderH1'>{__t("warning")}</h1>

          <p className='Paragraph'>{__t("header")}</p>
          <p className='Paragraph'>{__t("callout-a11y")}</p>
        </div>
        <ul className='disclaimer-ctas'>
          <li>
            <CTAButton
              primary
              onClick={(evt) => {
                document
                  .querySelectorAll("video")
                  .forEach((player) => player.play());
                goToNextPage(evt);
              }}
              className='cta-btn--image'
              intlKey={"button"}
              parentNode={parentNode}
            />
          </li>
          <li>
            <CTAButton
              secondary
              onClick={() => {
                window.location.href = hmButton.url;
              }}
              intlKey={"hmButton"}
              parentNode={parentNode}
            />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Disclaimer;
