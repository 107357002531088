const SvgDotMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 132 133"
    fill="none"
    {...props}
  >
    <g className="container" filter="url(#A)">
      <use fill="url(#B)" xlinkHref="#G"></use>
      <use stroke="#fff" strokeWidth="6" xlinkHref="#G"></use>
      <path
        stroke="url(#C)"
        strokeOpacity="0.8"
        strokeWidth="6"
        d="M123 62.5c0 31.78-25.543 57.5-57 57.5S9 94.28 9 62.5 34.543 5 66 5s57 25.72 57 57.5z"
      ></path>
    </g>
    <circle cx="37.375" cy="62.375" r="9.375" fill="url(#D)"></circle>
    <circle cx="65.5" cy="62.375" r="9.375" fill="url(#E)"></circle>
    <circle cx="93.625" cy="62.375" r="9.375" fill="url(#F)"></circle>
    <defs>
      <filter
        id="A"
        width="132"
        height="133"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="A"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="B"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="3"></feGaussianBlur>
        <feComposite in2="B" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        <feBlend in2="A"></feBlend>
        <feBlend in="SourceGraphic"></feBlend>
      </filter>
      <linearGradient id="B" x1="66" x2="66" y1="2" y2="123" xlinkHref="#H">
        <stop stopColor="#191925"></stop>
        <stop offset="1" stopColor="#251e2b"></stop>
      </linearGradient>
      <linearGradient id="C" x1="66" x2="66" y1="155" y2="46" xlinkHref="#H">
        <stop></stop>
        <stop offset="1" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        id="D"
        x1="37.375"
        x2="37.375"
        y1="57.219"
        y2="90.969"
        xlinkHref="#H"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#b8b8b8"></stop>
      </linearGradient>
      <linearGradient
        id="E"
        x1="65.5"
        x2="65.5"
        y1="57.219"
        y2="90.969"
        xlinkHref="#H"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#b8b8b8"></stop>
      </linearGradient>
      <linearGradient
        id="F"
        x1="93.625"
        x2="93.625"
        y1="57.219"
        y2="90.969"
        xlinkHref="#H"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#b8b8b8"></stop>
      </linearGradient>
      <path
        id="G"
        d="M123 62.5c0 31.78-25.543 57.5-57 57.5S9 94.28 9 62.5 34.543 5 66 5s57 25.72 57 57.5z"
      ></path>
      <linearGradient id="H" gradientUnits="userSpaceOnUse"></linearGradient>
    </defs>
  </svg>
);

export default SvgDotMenu;
