
export const supportedLocales = new Set([
  'en-US',
  'en',
  'es-AR',
  'es-CO',
  'es-CR',
  'es-EC',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PY',
  'es-SV',
  'es-UY',
  'es',
  'pt-BR',
  'pt'
]);
export const rollupLocales = new Map([
  [
    'en-US',
    [
      'global',
      'en',
      'en-US'
    ]
  ],
  [
    'en',
    [
      'global',
      'en'
    ]
  ],
  [
    'es-AR',
    [
      'global',
      'es',
      'es-AR'
    ]
  ],
  [
    'es-CO',
    [
      'global',
      'es',
      'es-CO'
    ]
  ],
  [
    'es-CR',
    [
      'global',
      'es',
      'es-CR'
    ]
  ],
  [
    'es-EC',
    [
      'global',
      'es',
      'es-EC'
    ]
  ],
  [
    'es-GT',
    [
      'global',
      'es',
      'es-GT'
    ]
  ],
  [
    'es-HN',
    [
      'global',
      'es',
      'es-HN'
    ]
  ],
  [
    'es-MX',
    [
      'global',
      'es',
      'es-MX'
    ]
  ],
  [
    'es-NI',
    [
      'global',
      'es',
      'es-NI'
    ]
  ],
  [
    'es-PA',
    [
      'global',
      'es',
      'es-PA'
    ]
  ],
  [
    'es-PE',
    [
      'global',
      'es',
      'es-PE'
    ]
  ],
  [
    'es-PY',
    [
      'global',
      'es',
      'es-PY'
    ]
  ],
  [
    'es-SV',
    [
      'global',
      'es',
      'es-SV'
    ]
  ],
  [
    'es-UY',
    [
      'global',
      'es',
      'es-UY'
    ]
  ],
  [
    'es',
    [
      'global',
      'es'
    ]
  ],
  [
    'pt-BR',
    [
      'global',
      'pt',
      'pt-BR'
    ]
  ],
  [
    'pt',
    [
      'global',
      'pt'
    ]
  ]
]);
