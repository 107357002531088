const SvgConfirm = (props) => (
    <svg viewBox="0 0 128 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="64" cy="60" r="48" fill="url(#gradient-confirm)" transform="matrix(1.280839, 0, 0, 1.239946, -18.237045, -14.372622)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M 63.737 119.541 C 97.692 119.541 125.217 92.896 125.217 60.024 C 125.217 27.153 97.692 0.507 63.737 0.507 C 29.783 0.507 2.256 27.153 2.256 60.024 C 2.256 92.896 29.783 119.541 63.737 119.541 Z M 63.737 109.621 C 92.033 109.621 114.97 87.416 114.97 60.024 C 114.97 32.631 92.033 10.427 63.737 10.427 C 35.441 10.427 12.502 32.631 12.502 60.024 C 12.502 87.416 35.441 109.621 63.737 109.621 Z" fill="url(#gradient-stroke-confirm)" />
        <path d="M 97.355 36.837 C 100.356 39.743 100.356 44.453 97.355 47.36 L 58.317 84.641 C 56.41 86.462 53.376 86.553 51.355 84.85 L 30.12 66.945 C 27.119 64.04 27.119 59.329 30.12 56.425 C 33.12 53.52 37.987 53.52 40.987 56.425 L 53.931 67.833 L 86.485 36.837 C 89.488 33.932 94.353 33.932 97.355 36.837 Z" fill="url(#gradient-pause-bars-confirm)" />
      
        <defs>
    <linearGradient id="gradient-confirm" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#B70700" />
      <stop offset="100%" stop-color="#5A1815" />
    </linearGradient>

    <linearGradient id="gradient-stroke-confirm" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#FCE685" />
      <stop offset="20%" stop-color="#EBB261" />
      <stop offset="50%" stop-color="#FEF08B" />
      <stop offset="80%" stop-color="#EAAA56" />
      <stop offset="100%" stop-color="#F1B02D" />
    </linearGradient>

    <linearGradient id="gradient-pause-bars-confirm" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#FCE685" />
      <stop offset="100%" stop-color="#F1B02D" />
    </linearGradient>
  </defs>  
    </svg>


)

export default SvgConfirm;