const SvgSkip = (props) => (
  <svg
    width={132}
    height={133}
    viewBox="0 0 132 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g className="container" filter="url(#filter0_d_729_125342)">
      <g className="icon" filter="url(#filter1_d_729_125342)">
        <path
          d="M124.5 62.5C124.5 95.0966 98.2969 121.5 66 121.5C33.7031 121.5 7.5 95.0966 7.5 62.5C7.5 29.9034 33.7031 3.5 66 3.5C98.2969 3.5 124.5 29.9034 124.5 62.5Z"
          fill="url(#paint0_linear_729_125342)"
        />
        <path
          d="M124.5 62.5C124.5 95.0966 98.2969 121.5 66 121.5C33.7031 121.5 7.5 95.0966 7.5 62.5C7.5 29.9034 33.7031 3.5 66 3.5C98.2969 3.5 124.5 29.9034 124.5 62.5Z"
          stroke="white"
          strokeWidth={3}
        />
        <path
          d="M124.5 62.5C124.5 95.0966 98.2969 121.5 66 121.5C33.7031 121.5 7.5 95.0966 7.5 62.5C7.5 29.9034 33.7031 3.5 66 3.5C98.2969 3.5 124.5 29.9034 124.5 62.5Z"
          stroke="url(#paint1_linear_729_125342)"
          strokeOpacity={0.8}
          strokeWidth={3}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93 41C93 38.2386 90.7614 36 88 36C85.2386 36 83 38.2386 83 41V84C83 86.7614 85.2386 89 88 89C90.7614 89 93 86.7614 93 84V41ZM50.6236 37.9126L76.4743 58.831V58.8529C78.4822 60.4788 78.4822 63.545 76.4743 65.1709L50.6236 86.0894C47.9611 88.2391 44 86.3404 44 82.9249V41.0771C44 37.6507 47.9611 35.7629 50.6236 37.9126Z"
        fill="url(#paint2_linear_729_125342)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_729_125342"
        x={0}
        y={0}
        width={132}
        height={133}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_729_125342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_729_125342"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_729_125342"
        x={0}
        y={0}
        width={132}
        height={133}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_729_125342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_729_125342"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_729_125342"
        x1={66}
        y1={2}
        x2={66}
        y2={123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191925" />
        <stop offset={1} stopColor="#251E2B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_729_125342"
        x1={66}
        y1={155}
        x2={66}
        y2={46}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_729_125342"
        x1={68.5}
        y1={58.1668}
        x2={68.5}
        y2={114.815}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgSkip;
