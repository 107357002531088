
import { useRef } from 'react';
import { whereAny, equals } from '../../services/ramda';
import { AoAnimation, AoFilterObject, } from './types';

const getExtension = (url: string): string => {
  const parts = url.split('.');
  if (typeof parts === 'undefined' || parts.length < 2) { throw new Error(`URL ${url} MISSING READABLE EXTENSION.`); }
  const ext = parts.pop();
  if (typeof ext === 'undefined') { throw new Error(`URL ${url} MISSING READABLE EXTENSION.`); }
  return ext.toUpperCase();
}

export function inferPlayerTypeFromUrl(url: string): string {
  if (!url) { throw new Error('URL NOT PROVIDED'); }
  const ext = getExtension(url);
  const type = ext === 'JSON' ? 'LOTTIE' : ext;
  return type;
}

export function inferClassNameFromName(name: string): string {
  return `animation-overlay-item--${name.replace('_', '-').toLowerCase()}`;
}

export function handlePause({ filters, animations, pause = true }: { filters: AoFilterObject, animations: AoAnimation[], pause: boolean }) {
  console.log('pause', filters, Object.entries(filters));
  if (Object.entries(filters).length === 0) {
    return animations.map((a: AoAnimation) => ({ ...a, paused: pause }));
  } else {
    const pred = whereAny(filters);
    return animations.map((a: AoAnimation) => ({ ...a, paused: pause ? pred(a) : !pred(a) }));
  }
}

export function handleClear({ filters, animations }: { filters: AoFilterObject, animations: AoAnimation[] }) {
  console.log('clear', filters);
  if (!filters) {
    return [];
  } else {
    const spec = Object.entries(filters).forEach(([v, k]) => [k, equals(k)]);
    console.log('spec', spec);
    return animations.filter((a: AoAnimation) => whereAny(spec, a));
  }
}

// const ucfirst = (v: string) => (v[0].toUpperCase() + v.substring(1));
// const actionTypes = ["clear", "setConfig", "mergeConfig", "add", "remove", "pause", "play", "complete"];
// const actionsString = actionTypes.map(v => ucfirst(v)).join('|');

// const getEventHanderName = (type) => `on${ucfirst(type)}`;

// /**
//  * Apply a middleware for all on{action.type) payload props
//  */
// export function createInterceptDispatch(state) {

//   console.log('createInterceptDispatch', state);

//   return (dispatch, {  payload, type  }) => {

//     console.log('createInterceptDispatch', dispatch, payload, type);

//     const currentEventName = getEventHanderName(type);
//     const handlers = useRef([]);

//     const matchFn = (a: AoAnimation) => {

//       let newPayload = {};
//       let newHandlers = [];

//       Object.keys(a).map(key => {
//         if (key.match(`on${ucfirst(key)}`)) {
//           newHandlers.push({ name: `on${ucfirst(key)}`, fn: a[key] });
//         }
//         else {
//           newPayload[key] = a[key];
//         }

//         return [newPayload, newHandlers];
//       })
//     };

//     const mapFn = typeof payload === 'string'
//       ? (a) => state.animations.find(a => a.hash === payload).map(matchFn)
//       : (a) => matchFn(payload);

//     const [newPayload, newHandlers] = mapFn(payload);

//     handlers.current = [...handlers.current, ...newHandlers].filter((handler) => {
//       if (handler.name.matches(currentEventName)) {
//         handler.fn();
//         return false;
//       }
//       return handler;
//     });

//     console.log('createInterceptDispatch',  { type, payload: newPayload });

//     return dispatch({ type, payload: newPayload });
//   }
// }