import { useState, useRef, useEffect, useCallback, useId } from "react";
import { useStore, useStoreApi } from "@tmsw/powerups.store";
import _ from "lodash";
// import Loader from "components/Organisms/Loader";
import { logger } from "@tmsw/powerups.utils.logger";
// import { convertToTimecode } from "../../../services/timecode/timecode";
import { getMetadata } from "@tmsw/powerups.utils.get-metadata";
// import { useAnimationOverlayContext } from "../../AnimationOverlay"

// PowerUp sub components
import { TextOverlay, InterfacePlayer, ButtonTemplates } from ".";
import "./PowerUp.css";
import { isIOS } from "./../../../hooks/isIOS";
import { parseInt } from "lodash/string";
import { AudioDescriptions } from "../../AudioDescriptions/AudioDescriptions";
import { useVolume } from "@tmsw/powerups.audio.hooks.use-volume";
import LoadingGif from "./loading.gif";
import { emitEvent } from "@tmsw/powerups.hooks.use-global-listener";

var animFrame,
  playCount = 0,
  syncCount = 0;

let tempBeatsList = [];

let gainNode;
let audioCtx;

const VIE = (props) => {
  const {
    InterfaceVideo,
    ToyVideo,
    Metadata,
    Captions,
    Descriptions,
    TextOverlays,
    Templates,
    onCueChange,
    chapterOverride,
    // showTemplates,
    onEnded,
    //onReady,
    onNextPage,
    onPrevPage,
    onGoToPage,
    setBeatsList,
    Overlays,
    Poster,
    hide,
    style,
    // analyticsDebug,
    // analyticsRelease,
    // controls,
  } = props;

  const { connectAudioNode, updateVolume } = useVolume();

  // store state
  const currentVolume = useStore((state) => state.currentVolume);
  const currentCueMetadata = useStore((state) => state.currentCueMetadata);
  const setCurrentCueMetadata = useStore(
    (state) => state.setCurrentCueMetadata
  );
  const metadataTrack = useStore((state) => state.metadataTrack);
  const setMetadataTrack = useStore((state) => state.setMetadataTrack);
  const viewport = useStore((state) => state.viewport);
  const viewportHeight = useStore((state) => state.viewportHeight);
  const play = useStore((state) => state.play);
  const setPlay = useStore((state) => state.setPlay);
  const isPlaying = useStore((state) => state.isPlaying);
  const setIsPlaying = useStore((state) => state.setIsPlaying);
  const setIsInterrupterModalDisplayed = useStore(
    (state) => state.setIsInterrupterModalDisplayed
  );
  const setSkipTarget = useStore((state) => state.setSkipTarget);
  const setBackTarget = useStore((state) => state.setBackTarget);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const isPauseInterrupterDisplayed = useStore(
    (state) => state.isPauseInterrupterDisplayed
  );
  const { overridingChapter, setOverridingChapter } = useStore((state) => ({
    overridingChapter: state.overridingChapter,
    setOverridingChapter: state.setOverridingChapter,
  }));
  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  const { descriptionsReading, setDescriptionsReading } = useStore((state) => ({
    descriptionsReading: state.descriptionsReading,
    setDescriptionsReading: state.setDescriptionsReading,
  }));

  const [ready, setReady] = useState(false);
  const [targetCue, setTargetCue] = useState(null);
  const [lastMetadata, setLastMetadata] = useState(props.Metadata);

  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const isPauseMenuDisplayed = useStore((state) => state.isPauseMenuDisplayed);

  // eslint-disable-next-line no-unused-vars
  const [chaptersTrack, setChaptersTrack] = useState(null);
  const [targetOverride, setTargetOverride] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  // refs
  //const isPlayingRef = useRef(false);
  const currTimeRef = useRef();
  const canPlayThroughRef = useRef(false);
  const interfaceVideoRef = useRef();
  const toyVideoRef = useRef(null);
  const lastCue = useRef(null);
  const VideoSrcRef = useRef(InterfaceVideo);
  const vieRef = useRef();
  const isOverridingChapterRef = useRef(false);
  const { subscribe } = useStoreApi();

  let mediaElement;

  const engineId = useId();

  const videoContainerStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    // height: '100vh',
  };

  useEffect(() => {
    if (showSettingsPanel || isPauseMenuDisplayed) {
      AudioDescriptions.cancel();
    } else {
      AudioDescriptions.resume();
    }
  }, [showSettingsPanel, isPauseMenuDisplayed]);

  useEffect(() => {
    const unsubVolumeChanged = subscribe(
      (state) => state.currentVolume,
      (curr, prev) => {
        /*gainNode.gain.value = curr;*/
        updateVolume(curr);
      }
    );
    return () => {
      unsubVolumeChanged();
    };
  }, [subscribe]);

  // START :: CUT OVER TO NEW GLOBAL PLAYER

  /**
   * @name playVideos
   * @param {*} shouldPlay
   * @returns
   */
  const playVideos = useCallback(
    (shouldPlay) => {
      /*console.log(
        "ready",
        ready,
        "\nplay",
        play,
        "\nplayer.paused",
        interfaceVideoRef.current.paused,
        "\n!descriptionsReading",
        !descriptionsReading,
        "\n!isPauseEnabled",
        !isPauseEnabled,
        "\nshouldPlay",
        shouldPlay
      );*/
      shouldPlay =
        /*descriptionsReading === false &&*/ ready &&
        play &&
        !isPauseEnabled &&
        shouldPlay;
      //shouldPlay = isPlayingRef.current === true && shouldPlay;

      //console.log("shouldPlay", shouldPlay);

      return new Promise((resolve, reject) => {
        try {
          // toyVideoRef.current.play().then(() => {
          // toyVideoRef.current.pause();
          //console.log(`playVideos event time: ${interfaceVideoRef.current.currentTime}`);

          if (!shouldPlay) {
            // console.log("STOPPING!!!");
            interfaceVideoRef.current.pause();
          } else {
            //console.log("STARTING!!!");
            if (audioCtx && audioCtx.state === "suspended") {
              audioCtx.resume();
            }
            // interfaceVideoRef.current.muted = false;
            interfaceVideoRef.current.play().catch((error) => {
              console.error(error);
            });
          }

          // Show loading animation.
          /*var playPromise = interfaceVideoRef.current.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                if (!shouldPlay) {
                  //console.log("STOPPING!!!");
                  interfaceVideoRef.current.pause();
                } else {
                  console.log("STARTING!!!");
                }
                resolve();
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }*/
          /*interfaceVideoRef.current.play().then(() => {
           if (!shouldPlay) {
             console.log("STOPPING!!!");
             interfaceVideoRef.current.pause();
           } else {
             console.log("STARTING!!!");
           }
           resolve();
         });
         /*if (!sldPlay) {
           console.log("STOPPING!!!");
           interfaceVideoRef.current.pause();
           resolve();
         } else if (!isPlaying && shouldPlay) {

         } else {
           resolve();
         }*/
        } catch (e) {
          //console.log("Play Thrown Error");
          console.log(e);
        }
      });
    },
    [descriptionsReading, play, isPauseEnabled, ready]
  );

  /**
   * @name updateMetadata
   * @param {*} metadata
   */
  const updateMetadata = useCallback(
    (metadata) => {
      lastCue.current = metadata;
      console.log(`currentCueMetadata is set to: `, metadata);
      if (metadata) setCurrentCueMetadata(metadata);
    },
    [setCurrentCueMetadata]
  );

  const setVideoTime = useCallback(
    (time, clicked) => {
      setPlay(false);
      //TODO: HACK: Removing timing adjustment for iOS because they may have finally fixed this.
      return (interfaceVideoRef.current.currentTime =
        isIOS(12) && !isIOS(16.4) && clicked ? time + 0.4 : time);
    },
    [setPlay]
  );

  useEffect(() => {
    if (targetCue) {
      //Broadcast custom event to GTM
      const lastChapter = currentCueMetadata?.id || null;
      const newMetadata = JSON.parse(targetCue.text);
      if (!_.isEqual(currentCueMetadata, newMetadata)) {
        //console.log("FROM goToChapter")
        //updateMetadata(newMetadata);

        // console.log(`NAVIGATING TO: ${targetCue.id}`);

        if (toyVideoRef.current)
          toyVideoRef.current.currentTime = targetCue.startTime;
        //interfaceVideoRef.current.currentTime = targetCue.startTime;
        setVideoTime(targetCue.startTime, targetCue.clicked);
        currTimeRef.current = { ...interfaceVideoRef.current.currentTime };

        // console.log(`Attempting to set time to ${targetCue.startTime}`);

        try {
          if (
            process.env.REACT_APP_MODE === "app" ||
            process.env.REACT_APP_MODE === "debug"
          ) {
            if (lastChapter !== targetCue.id) {
              //window.location.href = encodeURI(`analytics://sendEvent?category=${process.env.REACT_APP_NAME}&action=Custom Screen View&label=${id}`);
            }
          } else {
            if (window.dataLayer) {
              window.dataLayer.push({
                category: process.env.REACT_APP_NAME,
                action: "Custom Screen View",
                label: targetCue.id,
              });
            }
          }
        } catch (e) {
          //console.log("analytics error");
        }
      }
    }
  }, [targetCue, currentCueMetadata, setVideoTime]);

  const goToChapter = useCallback(
    (chapters, clicked) => {
      //chapters = chapters;
      setPlay(false);
      //console.log("Go to Chapter: " + chapters.toString());
      //if (metadataTrack?.activeCues?.length) metadataTrack.activeCues[0].pauseOnExit = false;
      setCurrentCueMetadata(null);

      let id;

      if (clicked && chapters && chapters.length > 1) {
        setTargetOverride(chapters);
      }

      if (targetOverride && targetOverride.length) {
        id = targetOverride.shift();
        setTargetOverride(targetOverride);
      } else {
        id = chapters[Math.floor(Math.random() * chapters.length)];
      }

      if (id) {
        if (id.indexOf("EXIT") !== -1) {
          //console.log("Attempting to close webview");
          window.location.href = "uniwebview://close";
          window.close();
        } else if (id.indexOf("PAGE:") !== -1) {
          let pageCommand = id.split(":")[1];

          switch (pageCommand) {
            case "PREV":
              if (onPrevPage) onPrevPage();
              interfaceVideoRef.current.currentTime =
                interfaceVideoRef.current.duration;
              break;

            case "NEXT":
              if (onNextPage) onNextPage();
              interfaceVideoRef.current.currentTime =
                interfaceVideoRef.current.duration;
              break;

            default:
              if (!isNaN((pageCommand = parseInt(pageCommand)))) {
                if (onGoToPage) onGoToPage(pageCommand);
              }
              break;
          }
        } else {
          if (!metadataTrack?.cues) {
            //console.log("cues are null");
            interfaceVideoRef.current.resetTextTracks();
          }
          //console.log("Setting Target Cue", metadataTrack?.cues.getCueById(id));
          let cue = metadataTrack?.cues.getCueById(id);
          if (clicked && cue !== null) {
            cue.clicked = clicked;
          }
          setTargetCue(cue);
        }
      }
      //console.log("Go to id: " + id);
      //const { metadata } = interfaceVideoRef.current.resetTextTracks();
    }, // metadataTrack.cues,
    [
      metadataTrack,
      setIsInterrupterModalDisplayed,
      setPlay,
      targetOverride,
      onNextPage,
      onPrevPage,
      setCurrentCueMetadata,
    ]
  );

  /**
   * @name getChapterCue
   * @param {*} id
   * @returns VTTCue|null
   */
  const getChapterCue = useCallback((id) => {
    console.trace(getChapterCue);
    //const { metadata } = interfaceVideoRef.current.resetTextTracks();
    const metadata = metadataTrack;
    try {
      for (let cue of metadata) {
        if (cue.id === id) return cue;
      }
    } catch (e) {
      //console.log("getChapterCue ----------------------------------------");
      interfaceVideoRef.current.resetTextTracks();
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @name generateMetadata
   * @param {*} metadataf
   * @returns {void}
   */
  const generateMetadata = useCallback(
    (metadata) => {
      //console.log("PowerUp generateMetadata---------------------");
      for (let track of interfaceVideoRef?.current?.textTracks) {
        //if ((track.kind === "metadata" || track.kind === "chapters") && track.name === "PowerUpMetadata") {
        track.mode = "disabled";
        track.default = true;
        //}
      }

      let metadataTrack = interfaceVideoRef?.current?.addTextTrack("metadata");
      metadataTrack.name = "PowerUpMetadata";
      metadataTrack.mode = "showing";

      let audioCueOffset = 0;
      metadata.forEach((data, idx) => {
        let startTime = parseFloat(data["Start Time"]);

        //TODO: Determine a more elegant way to find duplicates
        let matchingCues = [];
        for (let c of metadataTrack.cues) {
          if (c.startTime === Number(startTime.toFixed(8))) {
            matchingCues.push(c);
          }
        }
        // merging duplicates cues together to support random targets
        if (matchingCues.length) {
          matchingCues.forEach((oldCue) => {
            let oldCueData = JSON.parse(oldCue.text);
            oldCueData.out_target.chapters.push(...[data["Out Target"]]);
            oldCue.text = JSON.stringify(oldCueData);
          });
        } else {
          let duration = parseFloat(data["Duration"]);

          let endTime = startTime + duration;
          if (!isNaN(startTime) && !isNaN(endTime)) {
            let markerData = {};
            markerData.id = data["Chapter"];
            let offset = 0;

            markerData.startTime = Number((startTime + offset).toFixed(2));
            markerData.endTime = Number((endTime + offset).toFixed(2));
            markerData.out_target = {
              chapters: [],
            };

            if (data["Out Target"])
              markerData.out_target.chapters.push(data["Out Target"]);
            markerData.animation = data["Animation"];
            markerData.companion_video = !!data["Companion Video"];
            markerData.a11y_button = !!data["Accessibility Button"];
            markerData.pauseButton = !!data["Pause Button"];
            markerData.volume_control = !!data["Volume Control"];
            if (data["Back Button Target"])
              markerData.back_button_target = [data["Back Button Target"]];
            if (data["Skip Button Target"])
              markerData.skip_button_target = [data["Skip Button Target"]];
            markerData.button_template = data["Button Template"];
            markerData.buttons = [];
            for (let i = 1; i < 5; i++) {
              let buttonTargets = data[`Button ${i} Target`];
              markerData.buttons[i - 1] = {
                label: data[`Button ${i} Label`] || "",
                ariaLabel: data[`Button ${i} Aria Label`] || "",
                target: {
                  chapters: buttonTargets
                    ? [...data[`Button ${i} Target`].split(",")]
                    : null,
                },
                image: data[`Button ${i} Image`],
                sound: data[`Button ${i} Sound`],
                textColor: data[`Button ${i} Text Color`],
                bkgdColor: data[`Button ${i} Background Color`],
                highlight: data[`Button ${i} Highlight`],
                animation: data[`Button ${i} Animation`],
              };
            }
            markerData.pauseOnExit = markerData.out_target.chapters.length > 0; // && !markerData.id.includes("LOOP");
            let newCue = new VTTCue(
              markerData.startTime,
              markerData.endTime,
              JSON.stringify(markerData)
            );
            newCue.id = markerData.id;
            newCue.pauseOnExit = markerData.pauseOnExit;
            metadataTrack.addCue(newCue);
          }
        }
      });

      metadataTrack.addEventListener(`load`, (evt) => {
        //console.log(`Metadata has loaded`);
        const { track } = metadataTrack;
        // force this track to become active so we can get the cues:
        track.mode = "showing";
        const { cues } = track;
        //console.log(`${cues.length} cues found`);
        setMetadataTrack(metadataTrack);
      });

      metadataTrack.addEventListener(`error`, (evt) => {
        //console.log(`METADATA did not load properly!!!!!!!!!!!!!!`);
      });

      /*metadataTrack.addEventListener(
        "cuechange",
        (event) => {
          if (interfaceVideoRef && interfaceVideoRef.current) {
            /!*console.log(
              "cuechange-capture:\n\tcurrentTime: " + interfaceVideoRef?.current.currentTime,
              event.currentTarget
            );
            console.log("\tcurrentCue: ", currentCueMetadata);*!/
          }
        },
        true
      );*/

      metadataTrack.addEventListener("cuechange", (event) => {
        try {
          /* if (!event.currentTarget?.activeCues?.length) {
            if (lastCue.current?.id?.startsWith("hit")) {
              console.log("clearing cue");
              updateMetadata(null);
            }
          } else*/ if (
            event.currentTarget &&
            event.currentTarget.activeCues &&
            event.currentTarget.activeCues.length &&
            !targetCue
          ) {
            let newCueIdx = event.currentTarget.activeCues.length - 1;
            if (newCueIdx >= 0) {
              // console.log(`CUE CHANGE to ${event.currentTarget.activeCues[newCueIdx].id}`);
              if (onCueChange) onCueChange(event.currentTarget.activeCues);
              let newCue = event.currentTarget.activeCues[newCueIdx];

              console.log(
                `newCue: ${newCue?.id}\nstart: ${newCue?.startTime}\tend: ${newCue?.endTime}\n pauseOnExit: ${newCue?.pauseOnExit}`
              );

              console.log(
                `currentTime ${interfaceVideoRef.current?.currentTime}\n ----------------------------------------`
              );

              let newMeta = JSON.parse(newCue.text);
              newMeta.pauseOnExit = newCue.pauseOnExit;
              updateMetadata(targetCue ? null : newMeta);
              if (newMeta.animation) {
                // console.log("added animation from metadata");
                //addOverlayAnimation({ name: newMeta.animation });
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      });

      setMetadataTrack(metadataTrack);
    },
    [
      /*addOverlayAnimation,*/
      onCueChange,
      updateMetadata,
      currentCueMetadata,
      descriptionsEnabled,
      setMetadataTrack,
    ]
  );

  /**
   * @name loadedMetadataHandler
   */
  const loadedMetadataHandler = useCallback(
    (metadataFile) => {
      //console.log(`PowerUp loadedMetadataHandler ${metadataFile} ---------------------`);

      const { chapters, metadata } = { ...metadataFile };

      if (
        interfaceVideoRef?.current &&
        metadataFile &&
        (!!chapters || !!metadata)
      ) {
        console.log("got the Data", metadataFile, chapters, metadata);
        //if (chapters) generateChapters(chapters);
        if (metadata) generateMetadata(metadata);

        interfaceVideoRef.current.onAfterLoadMetadata();
      } else {
        console.error(metadataFile);
      }

      //interfaceVideoRef.current.resetTextTracks();
      interfaceVideoRef.current.load();
      //
      //playVideos(true);
    },
    [generateMetadata]
  );

  /**
   * @name loadMetadataFile
   * @param {string|Promise} ConfigFile
   * @return {void}
   */
  const loadMetadataFile = useCallback(
    (ConfigFile) => {
      if (typeof ConfigFile == "string" && ConfigFile.length) {
        setLastMetadata(ConfigFile);
        console.log("loadMetadataFile :: ConfigFile=", ConfigFile);
        getMetadata({ file: ConfigFile })
          .then(
            (response) => {
              //console.log("sheet loaded");
              loadedMetadataHandler(response);
            },
            (error) => {
              console.log(`error loading sheet from path: ${ConfigFile}`);
              console.error(error);
            }
          )
          .catch((err) => {
            console.log(`error loading sheet from path: ${ConfigFile}`);
            console.error(err);
          });
      } else if (ConfigFile.length) {
        Promise.resolve(ConfigFile).then(function (data) {
          //Lowercase all keys in metadata objects
          var key,
            keys = Object.keys(data);
          var n = keys.length;
          var newobj = {};
          while (n--) {
            key = keys[n];
            newobj[key.toLowerCase()] = data[key];
          }
          loadedMetadataHandler(newobj);
        });
      }
    },
    [loadedMetadataHandler]
  );

  /**
   * @name buttonClickHandler
   * @param {*} chapter
   */
  const buttonClickHandler = useCallback(
    (chapter) => {
      //console.log("button was clicked");
      if (chapter?.length) {
        /*console.log(`buttonClickHandler PLAY = TRUE ---------------------`);
        setPlay(true);
        */
        setPlay(false);
        //console.log("BUTTON CLICKED for " + chapter);
        goToChapter(chapter, true);
      }
    },
    [goToChapter, setPlay]
  );

  // END :: CUT OVER TO NEW GLOBAL PLAYER

  /**f
   * @name toySyncHandler
   */
  const toySyncHandler = () => {
    //console.log(`time: ${interfaceVideoRef.current.currentTime}`);
    const syncThreshold = 0.125;
    const syncFrequencyPerSec = 0.5;
    const vidFrameRate = 24;

    if (currentCueMetadata && !!currentCueMetadata.companion_video) {
      const vidDtime =
        Math.abs(
          toyVideoRef?.current?.currentTime -
            interfaceVideoRef?.current?.currentTime
        ) || 0;

      if (
        vidDtime > syncThreshold &&
        !(syncCount % Math.floor(vidFrameRate / syncFrequencyPerSec))
      ) {
        /*console.log(`Toy Video Out of Sync #${syncCount}: Resyncing!!!`);
                console.log(`toyVid time diff: ${vidDtime}`)*/
        toyVideoRef.current.currentTime = interfaceVideoRef.current.currentTime;
      }
      syncCount < 2400 ? syncCount++ : (syncCount = 0);
    }

    "requestVideoFrameCallback" in HTMLVideoElement.prototype
      ? (animFrame = interfaceVideoRef.current.requestVideoFrameCallback(
          (now, metadata) => {
            toySyncHandler();
          }
        ))
      : (animFrame = requestAnimationFrame((now, metadata) => {
          toySyncHandler();
        }));
  };

  const determineMineType = (vidPath) => {
    if (vidPath.includes(".m3u8")) {
      return "application/x-mpegURL";
    } else if (vidPath.includes(".mpd")) {
      return "application/dash+xml";
    } else {
      return "video/mp4";
    }
  };

  const generateVideoSources = useCallback((vidSrc) => {
    if (vidSrc) {
      if (!_.isArray(vidSrc) && typeof vidSrc == "string") {
        vidSrc = [vidSrc];
      }
      return vidSrc.map((src, idx) => {
        return (
          <source
            src={src}
            key={`videoSrc_${idx}`}
            type={determineMineType(src)}
          />
        );
      });
    }
    return null;
  }, []);

  // on mount
  useEffect(() => {
    //console.log("PowerUp useEffect mount---------------------");

    if (toyVideoRef?.current) {
      toyVideoRef.current.controls = false;
      toyVideoRef.current.crossOrigin = "anonymous";
    }

    if (typeof Metadata == "string" && !Poster) {
      loadMetadataFile(Metadata);
    }

    // [TODO] move to Accessibilitu
    window.addEventListener("audioDescriptionEvent", (evt) => {
      logger(`audioDescriptionEvent: ${evt.detail.status}`);
      switch (evt.detail.status) {
        case "speaking":
          if (evt.detail.pauseVideo) {
            setPlay(false);
            //console.log(`reading pause started at ${convertToTimecode(interfaceVideoRef.current.currentTime)}`);
            setDescriptionsReading(true);
          }
          break;

        case "paused":
          if (!evt.detail.pauseVideo) {
            setDescriptionsReading(false);
            //console.log("AD event -- PLAY = TRUE");
            setPlay(true);
            //playVideos(true);
          }
          break;

        case "pending":
        default:
          break;
      }
    });

    return () => {
      // [TODO] metaTrack.cue unlisteners?
      // [TODO]
      /*console.log("UNMOUNTING interfaceVideo");
      interfaceVideo.onPlay = () => {};
      interfaceVideo.onPause = () => {};*/
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const handleReady = _.debounce((ready, setPlay, chapterOverride) => {
    if (ready) {
      console.log(
        "VIE READY -----------------------------------------------------------------"
      );
      //interfaceVideoRef.current.resetTextTracks();
      //setPlay(false);
      interfaceVideoRef.current.setAttribute("muted", "false");
      if (!!!chapterOverride) {
        setPlay(true);
      }
      emitEvent("VIE_READY");
    }
    // });
    // handleReady(ready, setPlay, chapterOverride);
  }, [ready, setPlay, chapterOverride]);

  useEffect(() => {
    if (Metadata && InterfaceVideo) {
      //console.log("Metadata or InterfaceVideo has changed - Resetting VIE");
      setReady(false);
      setPlay(false);
      setVideoLoaded(false);
      canPlayThroughRef.current = false;
    } else if (!InterfaceVideo) {
    }
  }, [Metadata, InterfaceVideo, setPlay]);

  // on update metadata
  useEffect(() => {
    ///setMetadataTrack(null);
    if (
      Metadata &&
      typeof Metadata === "string" &&
      Metadata !== lastMetadata &&
      !hide &&
      interfaceVideoRef.current
    ) {
      //console.log(`PowerUp useEffect Metadata ${Metadata} ---------------------`);
      setPlay(false);
      loadMetadataFile(Metadata);

      //loadedMetadataHandler(Metadata);
      // //console.log(`PowerUp useEffect Metadata PLAY = TRUE ---------------------`);
      // setPlay(true);
    } else if (!Metadata) {
      setLastMetadata(null);
      //console.log("Metadata check & setting play to false");
      setPlay(false);
      setIsPlaying(false);
      interfaceVideoRef.current.pause();
      vieRef.current.classList.add("hide");
      interfaceVideoRef.current.setAttribute("muted", "true");
    }
  }, [Metadata, loadMetadataFile, lastMetadata, setPlay, hide, setIsPlaying]);

  /*useEffect(() => {
    const vie_elem = document.getElementById("VIE-Player");
    const viePlayer_elem = vie_elem.querySelector(".interface-video-player");
    if (Metadata && vie_elem && !hide) {
      vie_elem.classList.remove("hide");
      viePlayer_elem.currentTime = 0;
    } else {
    }
  }, [Metadata, setPlay, hide, setIsPlaying]);*/

  // on update showTemplates
  // useEffect(() => {
  //   //console.log(`PowerUp useEffect showTemplates=${showTemplates} ---------------------`);
  //   var sheet = document.styleSheets[0];
  //   if (showTemplates) {
  //     sheet.addRule('#UI-Overlay button', 'border: 2px dashed red');
  //   } else {
  //     for (var i = 0; i < sheet.rules.length; i++) {
  //       if (sheet.rules[i].selectorText === '#UI-Overlay button') {
  //         sheet.deleteRule(i);
  //       }
  //     }
  //   }
  // }, [showTemplates]);

  /* useEffect(() => {
    if (overridingChapter && overridingChapter[0] === currentCueMetadata?.id) {
      setOverridingChapter(null);
    }
  }, [overridingChapter, currentCueMetadata]);*/

  useEffect(() => {
    if (!descriptionsReading) {
      AudioDescriptions.cancel();
    }
  }, [descriptionsReading]);

  useEffect(() => {
    if (
      overridingChapter &&
      ready &&
      !!interfaceVideoRef.current &&
      metadataTrack?.cues?.length
    ) {
      //console.log("overriding Chapter Effect", overridingChapter);
      goToChapter(overridingChapter, true);
      setOverridingChapter(null);
    }
  }, [
    overridingChapter,
    goToChapter,
    ready,
    metadataTrack?.cues,
    setOverridingChapter,
  ]);

  // on update chapterOverride
  useEffect(() => {
    //console.log("on update chapterOverride Effect", chapterOverride);
    if (chapterOverride) {
      setOverridingChapter([chapterOverride]);
      isOverridingChapterRef.current = true;
    } else {
      setOverridingChapter(null);
    }
  }, [chapterOverride, setOverridingChapter]);

  useEffect(() => {
    if (!mediaElement) {
      /*audioCtx = getContext() //new (window.AudioContext || window.webkitAudioContext)(); //getContext() ||
      gainNode = audioCtx.createGain();
      mediaElement = audioCtx.createMediaElementSource(interfaceVideoRef.current);
      mediaElement.connect(gainNode).connect(audioCtx._context.destination);;*/
      if (!isIOS(17)) {
        connectAudioNode(interfaceVideoRef.current);
      }
    }
  }, []);

  /**
   * Subscribe to currentCueMetadata updates from store
   */
  useEffect(() => {
    /*console.log(
      `useEffect\n\t:: interfaceVideoRef.current.paused: ${interfaceVideoRef.current.paused}\n\t:: play: ${play}`
    );*/
    if (
      (isPauseEnabled ||
        /*descriptionsReading ||*/
        isOverridingChapterRef.current) &&
      play
    ) {
      //console.log("PAUSE - (isPauseEnabled || descriptionsReading || isOverridingChapterRef.current) ")
      playVideos(false);
    } /* if (interfaceVideoRef.current.paused === play) */ else {
      playVideos(play);
    }
  }, [
    play,
    playVideos,
    isPauseEnabled,
    descriptionsReading,
    isOverridingChapterRef.current,
  ]);

  let toyVideoStyle = {
    display: "absolute",
    zIndex: 1,
  };

  useEffect(() => {
    if (currentCueMetadata) {
      //toyVideoStyle.display = !!currentCueMetadata.companion_video ? "inline-block" : "none";
      // setA11yEnabled(!!currentCueMetadata.a11y_button);  // commented out for BP2 as A11y menu is embeded in Settings Panel in BP2
      /*setVolumeControlEnabled(!!currentCueMetadata.volume_control);*/
      setSkipTarget(currentCueMetadata.skip_button_target);
      if (!isPauseEnabled) setBackTarget(currentCueMetadata.back_button_target);
    }
  }, [
    /*addOverlayAnimation,*/ currentCueMetadata,
    /*setA11yEnabled,*/ /*setVolumeControlEnabled,*/ setBackTarget,
    setSkipTarget,
  ]);

  useEffect(() => {
    VideoSrcRef.current = generateVideoSources(InterfaceVideo);
    if (VideoSrcRef.current) {
      vieRef.current.classList.remove("hide");
      interfaceVideoRef.current.currentTime = 0;
      updateVolume(currentVolume);
      //gainNode.gain.value = currentVolume;
    } else {
      interfaceVideoRef.current.load();
    }
  }, [InterfaceVideo, generateVideoSources]);

  const interfacePlayerProps = {
    //src: InterfaceVideo,
    tracks: {
      captions: { src: Captions },
      descriptions: { src: Descriptions },
      textOverlays: { src: TextOverlays },
    },
    poster: Poster,
    onClick: (evt) => {
      //const { metadata } = interfaceVideoRef.current.resetTextTracks();
      const metadata = metadataTrack;
      if (Poster && !metadata) {
        loadMetadataFile(Metadata);
        evt.currentTarget.style.pointerEvents = "none";
      }
    },
    /*onLoad: (evt) => {
      console.log("%cINTERFACE PLAYER", "font-size:36px;color:blue;", interfaceVideoRef.current);
    },*/
    onLoadedMetadata: () => {
      //console.log("loadedMetadata", "font-size:36px;color:blue;", interfaceVideoRef.current);
      // const temp = [];
      /*tempBeatsList.forEach(beat => {
        if (beat.startTime <= this.interfacePlayer.duration) {
          temp.push(beat.id);
        }
      });*/

      if (setBeatsList) setBeatsList(tempBeatsList.map((beat) => beat.id));
      //console.log("onLoadedMetadata setting play to true");
      //setPlay(true);
      interfaceVideoRef.current.resetTextTracks();
    },
    onCanPlayThrough: (evt) => {
      //console.log("onCanPlayThrough", canPlayThroughRef.current, isPlaying);

      if (!canPlayThroughRef.current) {
        canPlayThroughRef.current = true;

        setVideoLoaded(true);
        //setA11yEnabled(false);
      }

      //setReady(false);
      setReady(true);
    },
    onSeeked: () => {
      if (toyVideoRef.current)
        toyVideoRef.current.currentTime = interfaceVideoRef.current.currentTime;
      /* console.log(
        `SEEKED: currentCue: ${currentCueMetadata?.id}\nstart: ${currentCueMetadata?.startTime}\tend: ${currentCueMetadata?.endTime}`
      );
      console.log(`currentTime ${interfaceVideoRef.current?.currentTime}\n ----------------------------------------`);*/
      if (targetCue) {
        //console.log("Seek setting play to true");
        setTargetCue(null);
        isOverridingChapterRef.current = false;
        setPlay(true);
        //playVideos(true);
      }
    },
    onPlayCapture: (evt) => {
      //console.log(`play capture: ${playCount} -------------------------`);
      //const { metadata } = interfaceVideoRef.current.resetTextTracks();
      const metadata = metadataTrack;
      const currentCue = metadata?.activeCues?.[0] || null;
      //console.log(`currentCue: ${currentCue?.id}\nstart: ${currentCue?.startTime}\tend: ${currentCue?.endTime}`);
      //console.log(`currentTime ${evt.currentTarget.currentTime}\n----------------------------------------`);
      //console.log("toyVideoRef.current", toyVideoRef?.current);
      if (toyVideoRef.current?.src) {
        toyVideoRef.current
          .play()
          .then(() => {
            //toyVideoRef.current.pause();
            "requestVideoFrameCallback" in HTMLVideoElement.prototype
              ? interfaceVideoRef.current.requestVideoFrameCallback(
                  (now, metadata) => {
                    toySyncHandler();
                  }
                )
              : requestAnimationFrame((now, metadata) => {
                  toySyncHandler();
                });
          })
          .catch(console.error);
      }
      /*if (chapterOverride && chapterOverride !== currentCue.id) {
        evt.preventDefault();
        evt.stopPropagation();
        setPlay(false);
      }*/
    },
    onPlay: (evt) => {
      if (interfaceVideoRef.current.isMetadataAvailable()) {
        //console.log("Metadata Found!!");
        setIsPlaying(true);
        toyVideoRef?.current?.play();
      } else {
        //console.log("NO METADATA IS AVAILABLE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        if (Metadata) interfaceVideoRef.current.resetTextTracks();
      }
      //interfaceVideoRef.current.resetTextTracks();
    },
    onPauseCapture: (evt) => {
      //console.log("pause capture -------------------------", evt);
      //const { metadata } = interfaceVideoRef.current.resetTextTracks();
      const metadata = metadataTrack;
      const currentCue = metadata?.activeCues?.[0] || currentCueMetadata;
      /*console.log(
        `currentCue: ${currentCueMetadata?.id}\nstart: ${currentCueMetadata?.startTime}\tend: ${currentCue?.endTime}\n pauseOnExit: ${currentCueMetadata?.pauseOnExit}\``
      );*/

      //console.log(`currentTime ${interfaceVideoRef.current.currentTime}\n----------------------------------------`);

      "requestVideoFrameCallback" in HTMLVideoElement.prototype
        ? interfaceVideoRef.current.cancelVideoFrameCallback(animFrame)
        : cancelAnimationFrame(animFrame);

      if (
        (!isOverridingChapterRef.current &&
          Math.abs(
            interfaceVideoRef.current.currentTime - currentCueMetadata?.endTime
          ) < 0.5) ||
        interfaceVideoRef.current.currentTime >= currentCueMetadata?.endTime
      ) {
        if (currentCueMetadata?.out_target.chapters.length) {
          //console.log("on Pause chapter check: setting play to false");
          setPlay(false);
          if (
            currentCueMetadata?.id !==
            currentCueMetadata?.out_target.chapters[0]
          ) {
            // console.log("currentCueMetadata?.id !== currentCueMetadata?.out_target.chapters[0]");
            goToChapter(currentCueMetadata?.out_target.chapters);
            // console.log(`PowerUp onPauseCapture PLAY = TRUE ---------------------`);
            //Test for and don't play APP PAGE Commands with a colon
            if (!/\w+:\w+/g.test(currentCueMetadata?.out_target.chapters[0]))
              setPlay(true);

            //playVideos(false).then(() => goToChapter(currentCueMetadata?.out_target.chapters));
          } else {
            //console.log("currentCueMetadata?.id == currentCueMetadata?.out_target.chapters[0]");
            if (
              interfaceVideoRef.current.currentTime >=
              currentCueMetadata?.endTime
            ) {
              //interfaceVideoRef.current.currentTime = currentCueMetadata.endTime;
              if (!currentCueMetadata.id.includes("LOOP")) {
                setVideoTime(currentCueMetadata.endTime - 0.1);
                //console.log("fixing overshoot: " + interfaceVideoRef.current.currentTime);
                setPlay(false);
              } else {
                setVideoTime(currentCueMetadata.startTime);
                setPlay(true);
                interfaceVideoRef.current.play();
              }
            }
          }
        }
      } /*else if (interfaceVideoRef.current.currentTime >= currentCueMetadata?.endTime) {
          console.log(currentCueMetadata, interfaceVideoRef.current.currentTime,  lastCue)
          //interfaceVideoRef.current.currentTime = currentCueMetadata.endTime;
          setVideoTime(currentCueMetadata.endTime - .1);
          /!*console.log("interfaceVideoRef.current.currentTime >= currentCueMetadata?.endTime\nfixing overshoot: " + interfaceVideoRef.current.currentTime);*!/
          setPlay(false);
      }*/ else {
        // console.log("pause bypass");
        if (isOverridingChapterRef.current) {
          setPlay(true);
        }
        // else if (interfaceVideoRef.current.duration - interfaceVideoRef.current.currentTime > 0.5) {
        // 	playVideos(true);
        // }
      }
    },
    onPause: (evt) => {
      toyVideoRef?.current?.pause();
      setIsPlaying(false);
    },
    onEnded: onEnded,
  };

  return (
    <div id={engineId} className='vie-video-background' ref={vieRef}>
      <div
        className='vie-video-container'
        style={{ opacity: videoLoaded ? 0.99999 : 0.00001 }}
      >
        <InterfacePlayer videoRef={interfaceVideoRef} {...interfacePlayerProps}>
          {VideoSrcRef.current}
        </InterfacePlayer>
        {currentCueMetadata && currentCueMetadata.companion_video && (
          <video
            className={"vie-toy-video-player"}
            ref={toyVideoRef}
            style={toyVideoStyle}
            src={ToyVideo}
            playsInline={1}
            muted
          />
        )}
        {!isPauseInterrupterDisplayed && Overlays}
        <TextOverlay />
      </div>
      {currentCueMetadata && (
        <div className='vie-ui-overlay' id={"UI-Overlay"}>
          <ButtonTemplates
            metadata={currentCueMetadata}
            onButtonClick={buttonClickHandler}
            templates={Templates}
          />
        </div>
      )}
    </div>
  );
};

export default VIE;
