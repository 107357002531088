import { AoAnimationInput, AoConfig } from './types';

const itemDefaults = {
  removeOnComplete: true,
  complete: false,
};

const locale = new URLSearchParams(window.location.search).get("locale") ?? "en-US";

const aoConfig:AoAnimationInput[] = [
  {
    name: 'HIT_SUCCESS_BLUE',
    url: "./static/media/en-US/anim/Level_HitAnimation-Blue_v01.json",
    width: 1080,
    height: 2280,
    style: {
      width: "100%",
      height: "100%",
      left: "50%",
      top: "50%",
      transform: `translate(-50%, -50%)`,
    },
  },
  {
    name: 'HIT_STREAK',
    url: "./static/media/en-US/anim/callout_150x_v003_export_wNumbers.json",
    width: 1000,
    height: 1000,
    style: {
      width: "100%",
      height: "100%",
      left: '0',
      top: "33%",
      position: "absolute",
    },
    audioDescription: "{x} times Streak!",
  },
  {
    name: 'GAME_NICE',
    url: `./static/media/en-US/anim/05_Nice!_v02.json`,
    width: 1080,
    height: 2280,
    style: {
      width: "100%",
      height: "100%",
      left: "50%",
      top: "50%",
      transform: `translate(-50%, -50%)`,
    },
    audioDescription: "Nice!",
  },
  {
    name: 'GAME_SUPER',
    url: `./static/media/${locale}/anim/06_Super_v02.json`,
    width: 1080,
    height: 2280,
    style: {
      width: "100%",
      height: "100%",
      left: "50%",
      top: "50%",
      transform: `translate(-50%, -50%)`,
    },
    audioDescription: "Super!",
  },
  {
    name: 'GAME_BUTTON_PRESS',
    url: "./static/media/en-US/anim/ButtonPress_v02_dev_v001.json",
    width: 1080,
    height: 2280,
    style: {
      width: "100%",
      height: "100%",
      left: "50%",
      top: "50%",
      transform: `translate(-50%, -50%)`,
    },
  },
  {
    name: 'DEVICE_ORIENTATION',
    url: "./static/media/en-US/anim/01_DeviceOrientation_v01.json",
    unique: true,
  },
  {
    name: 'TUTORIAL',
    url: "./static/media/en-US/anim/Tutorial_v006.json",
    unique: true,
    style: {
      height: "100%",
      width: "auto",
    }
  },
  {
    name: 'PAUSE',
    url: `./static/media/${locale}/anim/01_Pause_v004.json`,
    unique: true,
    style: {
      width: "20rem",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -55%)",
    },
    audioDescription: "Game Paused",
  },
  {
    name: 'RETURN_TO_ACTIVITY',
    url: `./static/media/${locale}/anim/02_ReturntoActivity_v05.json`,
    unique: true,
    style: {
      width: "20rem",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`,
    },
  },
  {
    name: 'ORIENTATION_CHANGE',
    url: "./static/media/en-US/anim/toVertical_ScreenRotation_v002.json",
    unique: true,
    style: {
      position: "relative",
      width: "auto",
      height: "91.3%"
    },
  },
  {
    name: 'GIF_TEST',
    url: "./static/media/en-US/anim/hit-error.gif",
    style: {
      width: "300px",
      left: "300px",
      transform: `translate(-50%, -50%)`,
    },
  },
];

aoConfig.forEach((item, index) => {
  aoConfig[index] = { ...itemDefaults, ...item };
});

export default aoConfig as AoConfig;
