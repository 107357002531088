const SvgReplay = (props) => (
    <svg width="120" height="120" viewBox="0 0 128 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M117 60.5C117 92.2799 91.4568 118 60 118C28.5432 118 3 92.2799 3 60.5C3 28.7201 28.5432 3 60 3C91.4568 3 117 28.7201 117 60.5Z" fill="url(#gradient)" stroke="url(#gradient-stroke)" strokeWidth="8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3769 23.358C57.6263 21.9838 56.4261 20.7837 55.0519 21.033L33.3402 24.9729C31.7524 25.261 31.142 27.2139 32.283 28.3549L36.8746 32.9465C28.6537 40.0273 23 50.8941 23 62.4224C23 83.1397 39.8037 99.9651 60.4993 99.9651C81.196 99.9651 97.9986 83.1278 97.9986 62.4224C97.9986 45.0611 86.2916 30.5291 70.3278 26.1821C67.6634 25.4566 64.9153 27.0284 64.1898 29.6928C63.4642 32.3572 65.036 35.1053 67.7004 35.8308C79.426 39.0237 87.9986 49.6894 87.9986 62.4224C87.9986 77.618 75.6601 89.9651 60.4993 89.9651C45.3374 89.9651 33 77.6277 33 62.4224C33 53.7813 37.4776 45.3426 43.9714 40.0433L50.055 46.1269C51.196 47.2679 53.149 46.6575 53.4371 45.0698L57.3769 23.358Z" fill="url(#gradient-pause-bars)"/>
    
     
        <defs>
    <linearGradient id="gradient" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#B70700" />
      <stop offset="100%" stop-color="#5A1815" />
    </linearGradient>

    <linearGradient id="gradient-stroke" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#FCE685" />
      <stop offset="20%" stop-color="#EBB261" />
      <stop offset="50%" stop-color="#FEF08B" />
      <stop offset="80%" stop-color="#EAAA56" />
      <stop offset="100%" stop-color="#F1B02D" />
    </linearGradient>

    <linearGradient id="gradient-pause-bars" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#FCE685" />
      <stop offset="100%" stop-color="#F1B02D" />
    </linearGradient>
  </defs>
    
    </svg>
);

export default SvgReplay;