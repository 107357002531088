import { DescriptionsPlayback } from "../components/AudioDescriptions";
import { useEffect } from "react";
import { useStore } from "@tmsw/powerups.store";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { A11yMenu } from "./A11yMenu";
function AccessibilityMenu() {
  const { __t } = useTranslation("A11Y_MENU");
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const setPageTitle = useStore((state) => state.setPageTitle);
  const pageTitle = __t("pageTitle");
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const inPortrait = useStore((state) => state.inPortrait);
  useEffect(() => {
    setPageTitle(pageTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  return (
    <>
      <A11yMenu />
      <DescriptionsPlayback />
    </>
  );
}

export default AccessibilityMenu;
