import PropTypes from 'prop-types';
import LottiePlayer from './Players/LottiePlayer';

const componentMap = {
  LOTTIE: LottiePlayer,
};

function AnimationOverlayItem(props) {
  const {
    width,
    height,
    scale,
    type,
    className,
    removeOnComplete,
    style,
    ...playerProps
  } = props;

  // playerProps.width = width * scale;
  // playerProps.height = height * scale;

  const divStyle = {
    ...{ width: typeof width === 'number' ? `${width * scale}px` : null },
    ...{ height: typeof height === 'number' ? `${height * scale}px` : null },
    ...style,
  };

  const PlayerComponent = componentMap[type];

  return (
    <div
      key={playerProps.hash}
      className={`animation-overlay-item ${className}`}
      style={divStyle}
    >
      <PlayerComponent {...playerProps} />
    </div>
  );
}

AnimationOverlayItem.defaultProps = {
  style: {},
  className: null,
};

AnimationOverlayItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onComplete: PropTypes.func,
  removeOnComplete: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  scale: PropTypes.number,
  type: PropTypes.string,
};

export default AnimationOverlayItem;
