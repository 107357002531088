const SvgPause = (props) => (
  <svg width="120" height="120" viewBox="0 0 125 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117 60.5C117 92.2799 91.4568 118 60 118C28.5432 118 3 92.2799 3 60.5C3 28.7201 28.5432 3 60 3C91.4568 3 117 28.7201 117 60.5Z" fill="url(#gradient-pause)" stroke="url(#gradient-stroke-pause)" strokeWidth="8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M52 40C52 37.2386 49.7614 35 47 35C44.2386 35 42 37.2386 42 40V80.4798C42 83.2413 44.2386 85.4798 47 85.4798C49.7614 85.4798 52 83.2413 52 80.4798V40ZM67.3493 40C67.3493 37.2386 69.5879 35 72.3493 35C75.1107 35 77.3493 37.2386 77.3493 40L77.3493 80.4798C77.3493 83.2413 75.1107 85.4798 72.3493 85.4798C69.5879 85.4798 67.3493 83.2413 67.3493 80.4798L67.3493 40Z" fill="url(#gradient-pause-bars-pause)"/>
    
    <defs>
      <linearGradient id="gradient-pause" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#B70700" />
        <stop offset="100%" stopColor="#5A1815" />
      </linearGradient>
  
      <linearGradient id="gradient-stroke-pause" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#FCE685" />
        <stop offset="20%" stopColor="#EBB261" />
        <stop offset="50%" stopColor="#FEF08B" />
        <stop offset="80%" stopColor="#EAAA56" />
        <stop offset="100%" stopColor="#F1B02D" />
      </linearGradient>
  
      <linearGradient id="gradient-pause-bars-pause" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#FCE685" />
        <stop offset="100%" stopColor="#F1B02D" />
      </linearGradient>
    </defs>
  </svg>
  );
  
  export default SvgPause;
  