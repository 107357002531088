import { useEffect, useState } from "react";
import { useStore, useStoreApi } from "@tmsw/powerups.store";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import VIEButton from "../../components/Templates/VIE/ButtonTemplates/VIEButton";
import { useSkipChapter } from "@tmsw/powerups.vie.hooks.use-skip-chapter";
import { useVieConfig } from "@tmsw/powerups.vie.hooks.use-vie-config";
import "./GamePlay.css";
import useResetFocus from "../../hooks/useResetFocus";

export const GamePlay = ({}) => {
  const missionNode = "CHARACTER_SELECTION";
  const completionNode = "GAME_COMPLETION";
  const endGameNode = "END_GAME";
  const translate = useTranslate();
  const { __t } = useTranslation(missionNode);
  const translatedConfig = __t("vieConfig");
  const skipChapter = useSkipChapter();
  const { vieConfigRef, updateVieConfig } = useVieConfig();
  const setPageTitle = useStore((state) => state.setPageTitle);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const inPortrait = useStore((state) => state.inPortrait);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const currentCueMetadata = useStore((state) => state.currentCueMetadata);
  const currentVideoChapterName = currentCueMetadata?.id;
  const [completionVisible, setCompletionVisible] = useState(false);
  const [selectMissionVisible, setSelectMissionVisible] = useState(false);

  const missions = __t("missions");
  const [jsonMission, setJsonMission] = useState();

  // h1's for the gameplay parts
  const h1Mission = translate("h1", missionNode);
  const h1Completion = translate("h1", completionNode);
  const h1EndGame = translate("h1", endGameNode);

  // buttons for the gameplay parts
  const endButton = translate("replayButton", endGameNode);
  const nextButton = translate("nextButton", completionNode);
  const replayButton = translate("replayButton", completionNode);

  // page titles for the gameplay parts
  const missionPageTitle = translate("pageTitle", missionNode);
  const completionPageTitle = translate("pageTitle", completionNode);
  const endPageTitle = translate("pageTitle", endGameNode);

  const endScreenChapterNames = [
    "CHAPTER_COMPLETION-5_LOOP-IN",
    "TRIGGER_CONTENT_COMPLETION-5_IN",
    "TRIGGER_COMPLETION-5_LOOP-OUT",
    "CHAPTER_COMPLETION-5_OUT",
  ];
  const endScreen = endScreenChapterNames.includes(currentVideoChapterName);

  //setting page titles depending on the gameplay part
  useEffect(() => {
    if (endScreen) {
      setPageTitle(endPageTitle);
    } else if (
      currentVideoChapterName?.includes("MISSION") ||
      currentVideoChapterName?.includes("COMPLETION")
    ) {
      setPageTitle(completionPageTitle);
    } else if (currentVideoChapterName?.includes("MENU")) {
      setPageTitle(missionPageTitle);
    }
  }, [
    currentVideoChapterName,
    showSettingsPanel,
    inPortrait,
    isPauseEnabled,
    endScreen,
  ]);

  useResetFocus([showSettingsPanel, inPortrait, isPauseEnabled]);

  const templates = {
    "CA Gameplay - 2 Button": (props) => {
      const { onButtonClick, buttons } = props;

      return (
        <>
          <ul role='list' className='gameplay-buttons'>
            <li>
              <VIEButton
                id={`NextMission`}
                idx={0}
                className='vie-selection-button cta-btn primary-cta-btn'
                buttonData={{
                  ...buttons[0],
                  label: nextButton.label,
                  ariaLabel: nextButton.ariaLabel,
                }}
                onButtonClick={onButtonClick}
                tabIndex={0}
              ></VIEButton>
            </li>
            <li>
              <VIEButton
                id={`Replay`}
                idx={0}
                className='vie-selection-button cta-btn secondary-cta-btn'
                buttonData={{
                  ...buttons[1],
                  label: replayButton.label,
                  ariaLabel: replayButton.ariaLabel,
                }}
                onButtonClick={onButtonClick}
                tabIndex={0}
              ></VIEButton>
            </li>
          </ul>
        </>
      );
    },

    "CA Gameplay - 1 Button": (props) => {
      const { onButtonClick, buttons } = props;
      const buttonClassName = endScreen
        ? "vie-selection-button cta-btn primary-cta-btn splash-cta"
        : `mission-button ${jsonMission.slug}`;

      const buttonLabel = endScreen ? endButton.label : jsonMission.label;
      const buttonAriaLabel = endScreen
        ? endButton.ariaLabel
        : jsonMission.ariaLabel;

      return (
        <>
          <VIEButton
            id='Select'
            idx={0}
            className={buttonClassName}
            buttonData={{
              ...buttons[0],
              label: buttonLabel,
              ariaLabel: buttonAriaLabel,
            }}
            onButtonClick={onButtonClick}
            tabIndex={0}
          ></VIEButton>
        </>
      );
    },
  };

  // syncing metadata mission with json mission and deviding gameplay into mission, completion and endscreen
  useEffect(() => {
    if (typeof currentVideoChapterName === "undefined") return;

    const isCompletion =
      /^CHAPTER_COMPLETION-\d+_LOOP-IN$|^TRIGGER_COMPLETION-\d+_LOOP-OUT$/.test(
        currentVideoChapterName
      ) && !endScreen;

    const isLevelSelection =
      /^TRIGGER_CONTENT_MENU-\d+_IN$|^CHAPTER_MENU-\d+_(LOOP-)?IN$|^TRIGGER_MENU-\d+_(LOOP-)?OUT$/.test(
        currentVideoChapterName
      );

    const matchResult = currentVideoChapterName.match(/(\d+)/);
    const currentMission = matchResult
      ? `mission-${matchResult[1]}`
      : undefined;

    const matchingMission = missions.find(
      (mission) => mission.slug === currentMission
    );

    matchingMission && setJsonMission(matchingMission);

    //reseting focus per game chapter
    const isResetFocus = /^TRIGGER_CONTENT_(COMPLETION|MENU)-\d+_IN$/.test(
      currentVideoChapterName
    );

    if (isResetFocus) {
      document.activeElement.blur();
      document.getElementById("SettingsPanel-Toggler")?.focus();
      console.log("CALLING FOCUS NOW");
    }

    updateVieConfig({
      templates: templates,
    });

    setCompletionVisible(isCompletion);
    setSelectMissionVisible(isLevelSelection);
  }, [currentVideoChapterName, endScreen]);

  // initial load on mount
  useEffect(() => {
    updateVieConfig({
      ...translatedConfig,
      skipChapter: skipChapter,
      templates: templates,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // custom update
  useEffect(() => {
    if (skipChapter !== vieConfigRef.current.skipChapter) {
      updateVieConfig({ skipChapter });
    }
  }, [skipChapter, updateVieConfig, vieConfigRef]);

  return (
    <section className='game'>
      {selectMissionVisible && (
        <div className='selection-header level fade-in'>
          <h1 className='selection-h1'>{h1Mission}</h1>
        </div>
      )}
      {completionVisible && (
        <div className='selection-header'>
          <h1 className='selection-h1 '>{h1Completion}</h1>
        </div>
      )}
      {endScreen && (
        <div className='selection-header level fade-in'>
          <h1 className='selection-h1'>{h1EndGame}</h1>
        </div>
      )}
    </section>
  );
};

export default GamePlay;
