
// configs
import { componentConfig } from './components';
import { intlConfig } from './intl';
import { pagesMap } from './pages';
// import { theme } from './stitches';

// providers
import { StoreProvider } from "@tmsw/powerups.store";
import { IntlProvider } from "@tmsw/powerups.intl.provider";
import { PageConfigProvider } from "@tmsw/powerups.config.page.provider";
import { AudioProvider } from "@tmsw/powerups.audio.provider";
import { NavBarProvider } from "@tmsw/powerups.ui.nav-bar";
import { BaseFontSize } from '../components/BaseFontSize';
// import { ThemeProvider } from "@tmsw/powerups.config.theme.provider";

export const appConfig = {
  components: componentConfig,
  pages: pagesMap
};

console.log('appConfig', appConfig);


export const globalContexts = [
  BaseFontSize,
  // [ThemeProvider, theme],
  [StoreProvider, {}],
  [IntlProvider, intlConfig],
  [PageConfigProvider, {pagesMap}],
  AudioProvider,
  [NavBarProvider, {}]
] as any;

console.log('APP_CONFIG app.ts', appConfig);

export default appConfig;