const SvgVolume = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 133"
    {...props}
  >
    <g className="container" filter="url(#a)">
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        fill="url(#b)"
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="#fff"
        strokeWidth={3}
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="url(#c)"
        strokeOpacity={0.8}
        strokeWidth={3}
      />
    </g>
    <g className="icon">
      <path fill="url(#d)" d="M75.1,82.7c-0.8,0-1.7-0.2-2.4-0.8c-1.8-1.3-2.2-3.8-0.9-5.6c1.1-1.5,3.1-4.8,3.1-9.3c0.1-5.8-2.9-9.7-3.2-10.2
    c-1.4-1.7-1.1-4.2,0.7-5.6c1.7-1.4,4.2-1.1,5.6,0.7c1.2,1.5,5.1,7.1,5,15.2c-0.1,6.4-2.6,11.1-4.7,13.9
    C77.5,82.1,76.3,82.6,75.1,82.7L75.1,82.7z M93.3,90.3c-0.8,0-1.6-0.2-2.3-0.7c-1.8-1.3-2.2-3.8-0.9-5.6c2.4-3.5,5.4-9.2,5.6-16.7
    c0.2-8.7-3.6-15.1-5.8-18.1c-1.3-1.8-0.9-4.3,0.8-5.6c1.8-1.3,4.3-0.9,5.6,0.8c2.8,3.8,7.6,12,7.4,23.1c-0.2,9.5-3.9,16.8-7,21.1
    C95.8,89.7,94.6,90.3,93.3,90.3z M60.9,50.9v34.7c0,3.6-4.6,5.5-7.5,3.1l-8.2-7l-3.4-3h-8.2c-2.9,0-5.2-2.3-5.2-5.2V63
    c0-2.9,2.3-5.2,5.2-5.2h8.2l3.4-3l8.2-7C56.3,45.4,60.9,47.2,60.9,50.9C60.9,50.9,60.9,50.9,60.9,50.9z"/>
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={66}
        y1={2}
        x2={66}
        y2={123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191925" />
        <stop offset={1} stopColor="#251E2B" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={66}
        y1={155}
        x2={66}
        y2={46}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={36}
        y1={57.5}
        x2={36}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={66}
        y1={57.5}
        x2={66}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={96}
        y1={57.5}
        x2={96}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <filter
        id="a"
        x={0}
        y={0}
        width={132}
        height={133}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_227_79261"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_227_79261"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgVolume;
