import { AppConfigProvider } from "@tmsw/powerups.config.app.provider";
// import { StoreProvider } from '@tmsw/powerups.store';
// import { IntlProvider } from '@tmsw/powerups.intl.provider';
// import { PageConfigProvider } from '@tmsw/powerups.config.page.provider';
// import { AudioProvider } from '@tmsw/powerups.ui.audio.provider';
// import { NavBarProvider } from '@tmsw/powerups.ui.nav-bar';
import GAME_PAGES from "./constants/GAME_PAGES";
import GameWrapper from "./components/GameWrapper";
import { Pages } from "./pages";
import Disclaimer from "./pages/Disclaimer/Disclaimer";
import Splash from "./pages/Splash/Splash";
// import { CameraAccessScreen } from "./pages/CameraAccessScreen/CameraAccessScreen";
// import { ToyScan } from './pages/ToyScan';
// import { ScanConfirmation } from "./pages/ScanConfirmation";
// import LevelSelection from "./pages/LevelSelection";
// import HowToPlay from "./pages/HowToPlay/HowToPlay";
// import GameInstructions from './pages/GameInstructions/GameInstructions';
import GamePlay from "./pages/GamePlay/GamePlay";
// import GameResults from "./pages/GameResults/GameResults";
import { appConfig, globalContexts } from "./config/app";
import "./App.css";

function App() {
  return (
    <AppConfigProvider config={appConfig} globalContexts={globalContexts}>
      <GameWrapper>
        {/* @ts-ignore */}
        <Disclaimer key={GAME_PAGES.DISCLAIMER} />
        <Splash key={GAME_PAGES.SPLASH} />
        {/* @ts-ignore */}
        {/* <CameraAccessScreen key={GAME_PAGES.CAMERA_ACCESS} /> */}
        {/* <HowToPlay key={GAME_PAGES.ONBOARDING} /> */}
        {/* <ToyScan key={GAME_PAGES.TOY_SCAN} /> */}
        {/* <ScanConfirmation key={GAME_PAGES.SCAN_CONFIRMATION} /> */}
        {/* <LevelSelection key={GAME_PAGES.LEVEL_SELECTION} /> */}
        {/* <GameInstructions key={GAME_PAGES.GAME_INSTRUCTIONS} /> */}
        <GamePlay key={GAME_PAGES.GAME_PLAY} />
        {/* <GameResults key={GAME_PAGES.END_GAME} /> */}
        {Object.entries(Pages).map(([pageKey, PageComponent]) => (
          <PageComponent key={pageKey} />
        ))}
      </GameWrapper>
    </AppConfigProvider>
  );
}

export default App;
