import { useTranslate } from '@tmsw/powerups.intl.legacy.hooks.use-translate';
import {
  ComponentClass, createContext, FC, memo, useCallback, useContext, useId, useReducer, useRef
} from 'react';
import { compose, equals } from '../../services/ramda';
import { useStore } from '@tmsw/powerups.store';
import AnimationOverlay from './AnimationOverlay';
import defaultConfig from './config';
import reducer from './reducer';
import { AoDispatchContextType, AoStateContextType, AoOverlayProps, AoAnimationInput, AoProviderProps } from './types';

let AoStateContext = createContext<AoStateContextType>({} as AoStateContextType);
let AoDispatchContext = createContext<AoDispatchContextType>({} as AoDispatchContextType);

function AoProvider({ children }: AoProviderProps) {

  const providerNameRef = useRef(useId());

  // non-context state
  const translate = useTranslate();
  const descriptionsEnabled = useStore((state: any) => state.descriptionsEnabled);

  const [state, dispatch] = useReducer(reducer, {
    providerName: providerNameRef.current,
    config: defaultConfig,
    animations: [],
  });

  const propsAreEqual = useCallback((prevProps:any, nextProps:any):boolean => equals(prevProps, nextProps), []);

  // const aoAdd = useCallback((payload) => {
  //   dispatch({ type: 'add', payload, translate, descriptionsEnabled });
  // }, [descriptionsEnabled, translate]);

  // const aoRemove = useCallback((payload) => {
  //   dispatch({ type: 'remove', payload });
  // }, []);

  const dispatchValueRef = useRef({
    aoAdd: (payload:AoAnimationInput) => {
      console.log('ANIMATION OVERLAY :: DISPATCHING ACTION', 'add', payload)
      dispatch({ type: 'add', payload, translate, descriptionsEnabled });
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    },
    aoRemove: (payload:any) => {
      console.log('ANIMATION OVERLAY :: DISPATCHING ACTION', 'remove', payload)
      dispatch({ type: 'remove', payload });
    },
 /* @ts-ignore */
    Overlay: memo((props: AoOverlayProps) => (<AnimationOverlay providerName={providerNameRef.current} {...props} />), propsAreEqual),
  });

  return (<AoDispatchContext.Provider value={dispatchValueRef.current}>
    <AoStateContext.Provider value={{
      ...state
    }}>
      {children}
    </AoStateContext.Provider>
  </AoDispatchContext.Provider>)
}

const wrapAoProvider = (WrappedComponent: ComponentClass | FC) => compose(
  (props: any) => (
    <AoProvider>
      <WrappedComponent {...props} />
    </AoProvider>)
);

const useAoContext = (contextType = AoDispatchContext) => {
  return useContext(contextType);
};

const useAoStateContext = () => {
  return useContext(AoStateContext);
};

export default AoDispatchContext;

export { AoProvider, wrapAoProvider, useAoContext, useAoStateContext };
