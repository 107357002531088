import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { useStore } from "@tmsw/powerups.store";
import { useEffect } from "react";

function Controls() {
  const { __t } = useTranslation("GAME_CONTROLS");

  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const setPageTitle = useStore((state) => state.setPageTitle);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const inPortrait = useStore((state) => state.inPortrait);

  useEffect(() => {
    const backButton: HTMLElement | null = document.querySelector(
      ".ui-buttons-circle-button"
    );
    if (backButton) {
      backButton.focus();
    }
    setPageTitle(__t("pageTitle"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  useEffect(() => {
    const waitToFindButtons = 10;
    setTimeout(() => {
      const settingsPanelContainer = document.querySelector(
        ".SettingsPanel[data-panel='KeyboardControls'] .SettingsPanel-Container"
      );

      if (settingsPanelContainer) {
        settingsPanelContainer.setAttribute("tabindex", "0");
      }
    }, waitToFindButtons);
  }, [showSettingsPanel]);

  useEffect(() => {
    // setPageTitle(pageTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  return (
    <section className='SettingsPanel-Container'>
      <h1 className='HeaderH1'>{__t("h1")}</h1>
      {/* <p>{__t("gameControlsBody1")}</p>
      <p>{__t("gameControlsBody2")}</p> */}
      <p className='Paragraph'>{__t("bodyCopy1")}</p>
      <p className='Paragraph'>{__t("bodyCopy2")}</p>
      {/* <p>
        <strong>{__t("gameControlsBody5")}</strong>
      </p> */}
      {/* <p className='Paragraph'>{__t("bodyCopy3")}</p> */}
    </section>
  );
}

export default Controls;
