import { equals, uniq } from '../../services/ramda';
import createAnimation from './factory/createAnimation';
import { handleClear, handlePause } from './helpers';
import { AoAnimation, AoState, AoAction } from './types';

export default function reducer(state: AoState, action: AoAction) {

  switch (action.type) {
    case 'clear':
      return { ...state, ...{ animations: handleClear({ filters: action.payload, animations: state.animations }) } };
    case 'setConfig':
      return { ...state, ...{ config: action.payload } };
    case 'mergeConfig':
      return { ...state, ...{ ...state.config, ...action.payload } };
    case 'add':
      const animation = createAnimation(state, action);
      console.log('ANIMATION OVERLAY REDUCER :: ADD', animation, state, action);
      const newState = { ...state, ...{ animations: uniq([animation, ...state.animations]) } };
      if(equals(newState.animations, state.animations)) {
        return state;
      }
      console.log('ANIMATION OVERLAY REDUCER :: NEW STATE!', newState, state);
      return newState;
    case 'remove':
      console.log('remove :: payload', action.payload);
      return { ...state, ...{ animations: state.animations.filter((a: AoAnimation) => a.hash !== action.payload.hash) } };
    case 'pause':
      return { ...state, ...{ animations: handlePause({ filters: action.payload, animations: state.animations, pause: true }) } };
    case 'play':
      return { ...state, ...{ animations: handlePause({ filters: action.payload, animations: state.animations, pause: false }) } };
    case 'complete':
      const pred = (a: AoAnimation) => a.complete && a.removeOnComplete && !a.loop ? false : { ...a, complete: true }
      const na = (a: AoAnimation) => (a.hash !== action.payload.hash); // N/A
      return { ...state, ...{ animations: state.animations.filter((a: AoAnimation) => na(a) ? a : pred(a)) } }
    default:
      throw new Error('unexpected action type');
  }
};