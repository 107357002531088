import { AudioDescriptions } from "../../AudioDescriptions/AudioDescriptions";
// import { AudioDescriptions } from "@tmsw/powerups.services.audio-descriptions";
/* @ts-ignore */
import { v4 as uuidv4 } from "uuid";
import { inferClassNameFromName, inferPlayerTypeFromUrl } from "../helpers";
import { AoActionAdd, AoAnimation, AoState } from "../types";

const parentNode = "ANIMATION";
const lastRef = { current: null };

export default function createAnimation(
  state: AoState,
  action: AoActionAdd
): AoAnimation {
  const { config, animations } = state;
  const { translate, descriptionsEnabled } = action;
  let { payload } = action;
  const { name } = payload;

  console.log("CREATE ANIMATION -------__-------------------", {
    state,
    action,
  });

  const configItem = config.find((item) => item.name === name);
  const animation = { ...configItem, ...payload } as AoAnimation;

  if (animation.unique) {
    animation.hash = animation.name;
    const runningAnimationIndex = animations.findIndex((a) => {
      return a.hash === animation.hash;
    });
    if (runningAnimationIndex !== -1) {
      return animations[runningAnimationIndex];
    }
  }

  if (typeof animation.hash === "undefined") {
    animation.hash = uuidv4();
  }

  const animationTranslation = translate(animation.name, parentNode);
  animation.ariaLabel = animationTranslation?.ariaLabel;
  animation.ariaDescription = animationTranslation?.ariaDescription;

  animation.className =
    animation.className ?? animation.name
      ? inferClassNameFromName(animation.name)
      : "unknown";
  animation.type = animation.type ?? inferPlayerTypeFromUrl(animation.url);

  if (descriptionsEnabled && animation?.audioDescription) {
    if (animation.var_x) {
      animation.audioDescription = animation.audioDescription.replace(
        "{x}",
        animation.var_x
      );
    }
    if (animation.name !== lastRef.current) {
      AudioDescriptions.speak(animation.audioDescription, false);
    }
  }
  /* @ts-ignore */
  lastRef.current = animation.name;
  return animation;
}
