import { useRef, useEffect } from "react";
import { CTAButton } from "@tmsw/powerups.ui.buttons.cta-button";
import { globalizedMedia } from "@tmsw/powerups.intl.hooks.use-localized-media";

const colorTest = /^#(?:[0-9a-f]{3}){1,2}$/i;

/*const blendModes = [
    "normal",
    "multiply",
    "screen",
    "overlay",
    "darken",
    "lighten",
    "color-dodge",
    "color-burn",
    "difference",
    "exclusion",
    "hue",
    "saturation",
    "color",
    "luminosity"
]*/

export const VIEButton = ({
  buttonData,
  onButtonClick,
  onTap,
  children,
  className,
  style,
  removeAfterClick,
  isCTAbtn,
  idx,
  ...props
}) => {
  const buttonEl = useRef();
  let buttonStyle = {
    ...style,
    touchAction: "manipulation",
    pointerEvents: "auto",
  };

  const { label, ariaLabel, target, image, textColor, bkgdColor, highlight } =
    buttonData;

  let { sound } = buttonData;

  const buttonClickHandler = (target) => {
    if (sound) {
      if (sound.indexOf("/") === -1) sound = globalizedMedia(sound, "audio");
      new Audio(sound).play();
    }
    if (target) onButtonClick(target);
    if (removeAfterClick) buttonEl.current.remove();
  };

  /*useEffect(() => {
    if (buttonEl.current) {
      buttonEl.current.addEventListener(
        "touchstart",
        (evt) => {
          if (onTap) {
            /!*evt.preventDefault();
            evt.stopPropagation();*!/
            onTap(evt);
            buttonClickHandler(target.chapters);
          }
        },
        { passive: false }
      );

      buttonEl.current.addEventListener(
        "touchend",
        (evt) => {
          if (onTap) {
            /!*evt.preventDefault();
            evt.stopPropagation();*!/
          }
        },
        { passive: false }
      );
    }
  }, []);*/

  useEffect(() => {
    //buttonEl?.current?.focus();

    return () => {
      buttonEl?.current?.blur();
      //buttonEl?.current?.setAttribute("aria-hidden", "true")
    };
  }, []);

  if (buttonData) {
    buttonStyle = Object.assign(
      {
        color: textColor && textColor.match(colorTest) ? textColor : "#000",
        backgroundColor:
          bkgdColor && bkgdColor.match(colorTest) ? bkgdColor : "initial",
        backgroundImage: image,
      },
      buttonStyle
    );

    return isCTAbtn ? (
      <CTAButton
        className={className}
        primary={idx % 2 === 0}
        onClick={(evt) => buttonClickHandler(target.chapters)}
        ariaLabel={ariaLabel}
        label={label}
      />
    ) : (
      <button
        ref={buttonEl}
        className={`vie-button ${className ? className : ""} ${
          highlight ? "highlight " + highlight : ""
        }`}
        style={buttonStyle}
        aria-label={ariaLabel}
        onClick={(evt) => buttonClickHandler(target.chapters)}
        {...props}
      >
        <span>{label}</span>
      </button>
    );
  } else {
    return (
      <button
        className={`vie-button ${className ? className : ""}`}
        style={buttonStyle}
      />
    );
  }
};

export default VIEButton;
