import React from "react";

export default function SettingsButtonGradient() {
  return (
    <svg
      width='0'
      height='0'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: "absolute", width: 0, height: 0 }}
      viewBox='0 0 125 120'
      fill='none'
      aria-hidden={true}
    >
      <defs>
        <linearGradient
          id='svgBtnStroke'
          gradientUnits='objectBoundingBox'
          x1='0'
          y1='0'
          x2='0'
          y2='1'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#A4B4C7' />
        </linearGradient>
      </defs>
    </svg>
  );
}
