const SvgPause = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 133"
    {...props}
  >
    <g className="container" filter="url(#a)">
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        fill="url(#b)"
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="#fff"
        strokeWidth={3}
      />
      <path
        d="M124.5 62.5c0 32.597-26.203 59-58.5 59S7.5 95.097 7.5 62.5s26.203-59 58.5-59 58.5 26.403 58.5 59Z"
        stroke="url(#c)"
        strokeOpacity={0.8}
        strokeWidth={3}
      />
    </g>
    <g className="icon">
    
    <path fill="url(#d)" d="M53.9,38.5L53.9,38.5c2.5,0,4.5,2,4.5,4.5v43c0,2.5-2,4.5-4.5,4.5l0,0c-2.5,0-4.5-2-4.5-4.5V43
	C49.4,40.5,51.4,38.5,53.9,38.5z M78.1,38.5L78.1,38.5c2.5,0,4.5,2,4.5,4.5v43c0,2.5-2,4.5-4.5,4.5l0,0c-2.5,0-4.5-2-4.5-4.5V43
	C73.6,40.5,75.6,38.5,78.1,38.5z" />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={66}
        y1={2}
        x2={66}
        y2={123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191925" />
        <stop offset={1} stopColor="#251E2B" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={66}
        y1={155}
        x2={66}
        y2={46}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={36}
        y1={57.5}
        x2={36}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={66}
        y1={57.5}
        x2={66}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={96}
        y1={57.5}
        x2={96}
        y2={93.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#B8B8B8" />
      </linearGradient>
      <filter
        id="a"
        x={0}
        y={0}
        width={132}
        height={133}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_227_79261"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_227_79261"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgPause;
