import { memo, useEffect, useRef, useCallback } from "react";
import { useStore } from "@tmsw/powerups.store";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { usePageConfig } from "@tmsw/powerups.config.page.provider";
import { AnimationInline } from "../AnimationOverlay";
import "./OrientationChangeReminder.css";

export type OrientationChangeReminderProps = {
  alertMountedWarning?: any,
  alertUnMountedWarning?: any,
};

export function OrientationChangeReminder({
  alertMountedWarning,
  alertUnMountedWarning,
}: OrientationChangeReminderProps) {
  const page = useStore((state) => state.currentPage);
  const { __t } = useTranslation("INTERRUPTER_ORIENTATION");
  const inPortrait = useStore((state) => state.inPortrait);
  // const setPageTitle = useStore((state) => state.setPageTitle);
  const setIsPauseEnabled = useStore((state) => state.setIsPauseEnabled);
  // const setIsPauseMenuDisplayed = useStore(
  //   useCallback((state) => state.setIsPauseMenuDisplayed, [])
  // );
  // const { pagesMap } = usePageConfig();
  const inPortraitRef = useRef(inPortrait);

  const { pagesMap } = usePageConfig();
  const setPageTitle = useStore((state) => state.setPageTitle);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);

  useEffect(() => {
    setPageTitle(__t("pageTitle"));
  }, [showSettingsPanel, isPauseEnabled, inPortrait]);

  useEffect(() => {
    // if (page === pagesMap.SPLASH || page === pagesMap.GAME_PLAY) {
    //add proper pages in here
    setIsPauseEnabled(true);
    // }
    if (inPortraitRef.current === inPortrait)
      return () => {
        alertUnMountedWarning();
        // setIsPauseMenuDisplayed(true);
      };
    // if (page === pagesMap.GAME_PLAY) {
    //   //add proper pages in here
    //   setIsPauseEnabled(true);
    // }
    if (inPortrait) {
      alertMountedWarning();
      // setPageTitle(translate('pageTitle', parentNode));
    }

    inPortraitRef.current = inPortrait;

    // return () => {
    //   if (page === pagesMap.GAME_PLAY) {
    //     //add proper pages in here
    //     setIsPauseEnabled(true);
    //   }
    // };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inPortrait]);

  return (
    <section
      className='orientation-interrupter'
      role='dialog'
      aria-modal='true'
    >
      <h1 className='orientation-interrupter-h1 HeaderH1' tabIndex={0}>
        {__t("h1")}
      </h1>
      {__t("bodyCopy", { defaultToKey: false }) && (
        <h2 className='orientation-interrupter-body HeaderH2' tabIndex={0}>
          {__t("bodyCopy")}
        </h2>
      )}
      <div id='orientation-interrupter-overlay'>
        <AnimationInline
          type='LOTTIE'
          url='./static/media/global/anim/ScreenRotation_Lottie.json'
          name='ORIENTATION_CHANGE'
          unique
          loop
          className='orientation-interrupter-overlay'
          alt={__t("imageAlt")}
        />
      </div>
    </section>
  );
}

OrientationChangeReminder.whyDidYouRender = true;

export default memo(OrientationChangeReminder);
