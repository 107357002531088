const SvgDotMenu = (props) => (
  <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117 60.5C117 92.2799 91.4568 118 60 118C28.5432 118 3 92.2799 3 60.5C3 28.7201 28.5432 3 60 3C91.4568 3 117 28.7201 117 60.5Z" fill="white" stroke="#0A3B81" strokeWidth="6"/>
    <circle cx="31.375" cy="60.375" r="9.375" fill="#0A3B81"/>
    <circle cx="59.5" cy="60.375" r="9.375" fill="#0A3B81"/>
    <circle cx="87.625" cy="60.375" r="9.375" fill="#0A3B81"/>
  </svg>
);

export default SvgDotMenu;
