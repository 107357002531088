const SvgPlay = (props) => (
	<svg width="132" height="133" viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_1902_168694)">
				<path d="M123 62.5C123 94.2799 97.4568 120 66 120C34.5432 120 9 94.2799 9 62.5C9 30.7201 34.5432 5 66 5C97.4568 5 123 30.7201 123 62.5Z" fill="url(#paint0_linear_1902_168694)"/>
				<path d="M123 62.5C123 94.2799 97.4568 120 66 120C34.5432 120 9 94.2799 9 62.5C9 30.7201 34.5432 5 66 5C97.4568 5 123 30.7201 123 62.5Z" stroke="white" strokeWidth="6"/>
				<path d="M123 62.5C123 94.2799 97.4568 120 66 120C34.5432 120 9 94.2799 9 62.5C9 30.7201 34.5432 5 66 5C97.4568 5 123 30.7201 123 62.5Z" stroke="url(#paint1_linear_1902_168694)" strokeOpacity="0.8" strokeWidth="6"/>
		</g>
		<path d="M85.4743 58.831L59.6236 37.9126C56.9611 35.7629 53 37.6507 53 41.0771V82.9249C53 86.3404 56.9611 88.2391 59.6236 86.0894L85.4743 65.1709C87.4822 63.545 87.4822 60.4788 85.4743 58.8529V58.831Z" fill="url(#paint2_linear_1902_168694)"/>
		<defs>
				<filter id="filter0_d_1902_168694" x="0" y="0" width="132" height="133" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="3"/>
					<feComposite in2="hardAlpha" operator="out"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1902_168694"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1902_168694" result="shape"/>
				</filter>
				<linearGradient id="paint0_linear_1902_168694" x1="66" y1="2" x2="66" y2="123" gradientUnits="userSpaceOnUse">
					<stop stopColor="#191925"/>
					<stop offset="1" stopColor="#251E2B"/>
				</linearGradient>
				<linearGradient id="paint1_linear_1902_168694" x1="66" y1="155" x2="66" y2="46" gradientUnits="userSpaceOnUse">
					<stop/>
					<stop offset="1" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="paint2_linear_1902_168694" x1="69.9901" y1="57.912" x2="69.9901" y2="111.354" gradientUnits="userSpaceOnUse">
					<stop stopColor="white"/>
					<stop offset="1" stopColor="#B8B8B8"/>
				</linearGradient>
		</defs>
	</svg>
);

export default SvgPlay;