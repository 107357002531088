//@ts-nocheck
import './LottiePlayer.css';

import Lottie, { LottieRef, LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import type { LottieAnimationData, LottiePlayerProps } from '../../types';

import getAnimationData from './getAnimationData';

export const LottiePlayer = (props: LottiePlayerProps): JSX.Element => {
  const {
    url,
    hash,
    name,
    onComplete,
    onEnterFrame,
    onLoopComplete,
    paused,
    action,
    onStart,
    direction,
    speed,
    loop,
    autoplay,
    ariaLabel,
    ariaDescription,
    refPassthrough,
    style,
  } = props;

  const playerRef: LottieRef = useRef<LottieRefCurrentProps | null>(null);
  refPassthrough(playerRef);

  const [loaded, setLoaded] = useState<string | null>(null);
  const [playing, setPlaying] = useState<string | null>(null);
  const [animationData, setAnimationData] =
    useState<LottieAnimationData | null>(null);

  const handleOnInit = (jsonData: any) => {
    action && action(jsonData);
    setAnimationData(jsonData);
    setPlaying(hash);
  };

  const loadAnimationData = async (url: any) => {
    return await getAnimationData(url);
  };

  // initialize player on mount
  useEffect(() => {
    if (loaded !== hash) {
      setLoaded(hash);
      loadAnimationData(url).then(handleOnInit);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hash, url, loaded]);

  // handle pausing
  useEffect(() => {
    if (!playerRef.current) return;
    if (paused && playing) {
      setPlaying(null);
    }
  }, [playing, paused]);

  // handle playing
  useEffect(() => {
    if (!playerRef.current) return;
    if (!paused && !playing) {
      setPlaying(hash);
    }
  }, [hash, paused, playing]);

  // handle direction
  useEffect(() => {
    if (!playerRef.current || !playerRef.current.animationItem) return;
    if (playerRef.current.animationItem.playDirection !== direction) {
      playerRef.current.setDirection(direction);
    }
  }, [hash, direction]);

  // handle speed
  useEffect(() => {
    if (!playerRef.current || !playerRef.current.animationItem) return;
    if (playerRef.current.animationItem.playSpeed !== speed) {
      playerRef.current.setSpeed(speed);
    }
  }, [hash, speed]);

  return (
    <div
      className="lottie-player-container"
      style={{ ...{ width: '100%', height: '100%' }, ...style }}
    >
      <Lottie
        {...{
          animationData,
          autoplay,
          name: name,
          loop,
          lottieRef: playerRef,
          onEnterFrame: (e) => {
            onEnterFrame(e, playerRef);
          },
          onLoopComplete: (e) => {
            onLoopComplete(e, playerRef);
            if (loop) return;
            const duration = playerRef.current.getDuration(true);
            playerRef.current.goToAndStop(duration, true);
            playerRef.current.pause();
          },
          onComplete: () => {
            onComplete(hash);
          },
          onDOMLoaded: () => {
            onStart(hash, playerRef);
          },
          renderer: 'svg',
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
            hideOnTransparent: false,
            progressiveLoad: true,
          },
          style: { width: '100%', height: '100%' },
          'aria-hidden': true, // added because some lottie animations contain purely presentation images not meant for screen readers
        }}
        {...(ariaDescription && {
          'aria-describedby': 'animation-description',
        })}
      />
      <div className="character-preview-sprite-shadow" aria-hidden="true"></div>
      {ariaLabel && (
        <div
          className="pseudo-aria-label-description"
          role="img"
          aria-label={ariaLabel}
        >
          {ariaLabel}
        </div>
      )}
      {ariaDescription && (
        <div
          id="animation-description"
          className="pseudo-aria-label-description"
        >
          {ariaDescription}
        </div>
      )}
    </div>
  );
};

LottiePlayer.defaultProps = {
  loop: false,
  autoplay: true,
  direction: 1,
  speed: 1,
  onStart: () => {},
  onEnterFrame: () => {},
  onLoopComplete: () => {},
  style: {},
  refPassthrough: (el) => el,
};

export declare type LottiePlayerType = typeof LottiePlayer;

export default LottiePlayer;
