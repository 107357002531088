import { useTranslate } from '@tmsw/powerups.intl.legacy.hooks.use-translate';
import useComputeRank from "../../../hooks/useComputeRank";
import './ResultsFeedback.css';

function ResultsFeedback ({animFlag}) {
  const translate = useTranslate();
  const computeRank = useComputeRank();
  const successCopy = translate("END_GAME");
  const successHeader = successCopy[computeRank()[1]];
  const numberOfMoves = computeRank()[2];

  return (
    <section className={"results-feedback"}>
      <div className = {`game-results-head ${animFlag}`}>
        {successHeader}
      </div>
      <div className = {`game-results-body ${animFlag}`}>
        {successCopy.bodyCopy1} {numberOfMoves} {successCopy.bodyCopy2}
      </div>
    </section>
  );
};

export default ResultsFeedback;

