import { useEffect, useRef } from "react";
import VIEButton from "./VIEButton";
import "./ButtonTemplates.css";
// import backButton from "../../../BackButton/button-back.svg";
import { useStore } from "@tmsw/powerups.store";
import ResultsFeedback from "../../ResultsFeedback/ResultsFeedback";

const BUTTON_TEMPLATES = {
  CAROUSEL: "Carousel - 3 button",
  INTRO: "Intro - 1 button",
  INSTRUCTIONS: "Instructions - 2 button",
  MENU_1_BUTTON: "Menu - 1 button",
  MENU_2_BUTTON: "Menu - 2 button",
  // NOTE: Not ideal to alter the below key, but was necessary to avoid 
  // metadata changes. May want to remove "(old)" for the future.
  MENU_4_BUTTON: "Menu - 4 button (old)",
  CONCLUSION_2_BUTTON: "Conclusion - 2 button",
  BEAT_GAME_1_BUTTON: "Beat Game - 1 button",
  END_OF_GAME_2_BUTTON: "End of game 2 buttons (old)",
  SPLASH_1_BUTTON: "Splash - 1 button",
  FULL_SCREEN_HORIZ_2_BUTTON: "Full Screen - Horizontal - 2 button",
  END_OF_GAME_TEXT_ANIMATION: "End of game text animation",
};

const backButtonStyle = {
  position: "absolute",
  top: "10%",
  left: "3.5%",
  height: "8%",
  width: "18%",
  borderRadius: "4rem",
  // background: `url(${backButton}) no-repeat center`,
  backgroundSize: "contain",
};

let lastTemplate, focusedButton;

export const ButtonTemplate = (props) => {
  const { metadata, onButtonClick, templates } = props;

  if (!metadata) debugger;

  const {
    score,
    setScore,
    streakCount,
    setStreakCount,
    lastHit,
    setLastHit,
    hitsCount,
    setHitsCount,
    beatsList,
  } = useStore((state) => ({
    score: state.score,
    setScore: state.setScore,
    streakCount: state.streakCount,
    setStreakCount: state.setStreakCount,
    lastHit: state.lastHit,
    setLastHit: state.setLastHit,
    hitsCount: state.hitsCount,
    setHitsCount: state.setHitsCount,
    beatsList: state.beatsList,
  }));

  useEffect(() => {
    if (metadata) {
      //console.log(`metadata.button_template: ${metadata.button_template}\nlastTemplate: ${lastTemplate}`);
      if (metadata.button_template === lastTemplate) {
        let lastButton = document.getElementById(focusedButton);
        if (document.activeElement.id.length === 0 && lastButton) {
          //console.log(`Setting Focus on ${lastButton.id}`)
          lastButton.focus();
          focusedButton = null;
        }
      } else if (metadata.button_template && metadata.button_template.length) {
        focusedButton = null;
      }
      if (
        metadata.button_template &&
        lastTemplate !== metadata.button_template
      ) {
        lastTemplate = metadata.button_template;
      }
    }
  });

  if (metadata) {
    const { button_template, buttons, back_button_target, skip_button_target } =
      { ...metadata };

    if (button_template !== lastTemplate) {
      focusedButton = document.activeElement.id || focusedButton;
    }

    if (buttons || back_button_target) {
      switch (button_template) {
        case BUTTON_TEMPLATES.INTRO:
          return (
            <ButtonTemplate.Intro
              buttons={buttons}
              onButtonClick={onButtonClick}
            />
          );
        //break;

        case BUTTON_TEMPLATES.INSTRUCTIONS:
          return (
            <ButtonTemplate.Instructions
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        //break;

        case BUTTON_TEMPLATES.CAROUSEL:
          return (
            <ButtonTemplate.Carousel
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        //break;

        case BUTTON_TEMPLATES.MENU_1_BUTTON:
          return (
            <ButtonTemplate.Menu1Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        case BUTTON_TEMPLATES.MENU_2_BUTTON:
          return (
            <ButtonTemplate.Menu2Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        //break;

        case BUTTON_TEMPLATES.MENU_4_BUTTON:
          return (
            <ButtonTemplate.Menu4Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        //break;

        case BUTTON_TEMPLATES.CONCLUSION_2_BUTTON:
          return (
            <ButtonTemplate.Conclusion2Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        case BUTTON_TEMPLATES.END_OF_GAME_2_BUTTON:
          return (
            <ButtonTemplate.EndOfGame2Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        case BUTTON_TEMPLATES.BEAT_GAME_1_BUTTON:
          return (
            <ButtonTemplate.BeatGame1Button
              id={metadata.id}
              buttons={buttons}
              onButtonClick={onButtonClick}
              score={score}
              setScore={setScore}
              beatsList={beatsList}
              streakCount={streakCount}
              setStreakCount={setStreakCount}
              lastHit={lastHit}
              setLastHit={setLastHit}
              hitsCount={hitsCount}
              setHitsCount={setHitsCount}
              backButtonTarget={back_button_target}
            />
          );

        case BUTTON_TEMPLATES.SPLASH_1_BUTTON:
          return (
            <ButtonTemplate.Splash1Button
              id={metadata.id}
              buttons={buttons}
              skipButtonTarget={skip_button_target}
              /*addOverlayAnimation={addOverlayAnimation}
               */ onButtonClick={onButtonClick}
              score={score}
              setScore={setScore}
              beatsList={beatsList}
              streakCount={streakCount}
              setStreakCount={setStreakCount}
              lastHit={lastHit}
              setLastHit={setLastHit}
              hitsCount={hitsCount}
              setHitsCount={setHitsCount}
            />
          );
        //break;

        case BUTTON_TEMPLATES.END_OF_GAME_TEXT_ANIMATION:
          return <ResultsFeedback animFlag="animated" />;

        case BUTTON_TEMPLATES.FULL_SCREEN_HORIZ_2_BUTTON:
          return (
            <ButtonTemplate.FullScreenHoriz2Button
              buttons={buttons}
              onButtonClick={onButtonClick}
              backButtonTarget={back_button_target}
            />
          );

        default:
          const FoundTemplate = templates?.[button_template];
          if (FoundTemplate) {
            return (
              <FoundTemplate
                buttons={buttons}
                onButtonClick={onButtonClick}
                backButtonTarget={back_button_target}
                skipButtonTarget={skip_button_target}
              />
            );
          } else {
            return (
              <ButtonTemplate.Default
                onButtonClick={onButtonClick}
                backButtonTarget={back_button_target}
                skipButtonTarget={skip_button_target}
              />
            );
          }
          break;
      }
    }
  }
  //alert("no template")
  return null;
};

ButtonTemplate.Intro = (props) => {
  const { onButtonClick, buttons } = props;

  return (
    <>
      <VIEButton
        id="Button1"
        idx={0}
        isCTAbtn
        className="cta-btn--image ok"
        onButtonClick={onButtonClick}
        buttonData={buttons[0]}
        tabIndex={5}
      />
    </>
  );
};

ButtonTemplate.Instructions = (props) => {
  const { onButtonClick, buttons } = props;
  return (
    <>
      {/* <svg
        className="replay-icon"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 96 96"
      >
        <circle cx="48" cy="48" r="48" fill="#232324" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48Zm0-8c22.091 0 40-17.909 40-40S70.091 8 48 8 8 25.909 8 48s17.909 40 40 40Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="m25.903 27.515 16.542-2.5.009.008c1.285-.194 2.445.86 2.39 2.172l-.716 16.878c-.075 1.737-2.116 2.569-3.408 1.395l-4.399-3.996A14.088 14.088 0 0 0 34 49.255c0 7.797 6.246 14.034 13.846 14.034S61.69 57.052 61.69 49.255c0-4.609-2.184-8.685-5.56-11.245a5 5 0 0 1 6.044-7.967 24.069 24.069 0 0 1 9.516 19.212c0 13.226-10.629 24.034-23.845 24.034C34.629 73.289 24 62.48 24 49.255A24.068 24.068 0 0 1 28.868 34.7l-3.98-3.615c-1.296-1.178-.688-3.311 1.015-3.57Z"
        />
      </svg>
      <VIEButton
        id="Button1"
        className="replay"
        onButtonClick={onButtonClick}
        buttonData={buttons[0]}
        tabIndex={0}
      />

      <VIEButton
        id="Button2"
        className="confirm"
        onButtonClick={onButtonClick}
        buttonData={buttons[1]}
        tabIndex={0}
      /> */}
    </>
  );
};

ButtonTemplate.Carousel = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;

  const carouselContainerStyle = {
    display: "flex",
    position: "absolute",
    bottom: "12%",
    width: "100%",
    height: "36%",
  };

  return (
    <>
      {backButtonTarget && backButtonTarget.length && (
        <VIEButton
          id="BackButton"
          style={backButtonStyle}
          onButtonClick={onButtonClick}
          buttonData={{
            target: {
              chapters: backButtonTarget,
            },
          }}
          aria-label={"go back"}
        />
      )}
      <nav style={carouselContainerStyle}>
        <VIEButton
          id="Button1"
          className="prev"
          onButtonClick={onButtonClick}
          buttonData={buttons[0]}
          tabIndex={0}
        />
        <VIEButton
          id="Button2"
          className="select"
          onButtonClick={onButtonClick}
          buttonData={buttons[1]}
          tabIndex={0}
        />
        <VIEButton
          id="Button3"
          className="next"
          onButtonClick={onButtonClick}
          buttonData={buttons[2]}
          tabIndex={0}
        />
      </nav>
    </>
  );
};

ButtonTemplate.Menu1Button = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;

  let containerStyle = {
    display: "flex",
    position: "absolute",
    bottom: "10%",
    width: "30%",
    height: "40%",
    flexWrap: "wrap",
    flexDirection: "row",
    opacity: 0.5,
  };
  let button = buttons[0];
  return (
    <>
      {/*{backButtonTarget && backButtonTarget.length && (
        <VIEButton
          id="BackButton"
          style={backButtonStyle}
          aria-label={"go back"}
          onButtonClick={onButtonClick}
          buttonData={{
            target: {
              chapters: backButtonTarget,
            },
          }}
        />
      )}*/}
      <ul
        className="cta-btn-list"
        style={{ top: "70vh", height: "max-content" }}
      >
        <li>
          <VIEButton
            isCTAbtn
            idx={0}
            id="Button1"
            className="one"
            onButtonClick={onButtonClick}
            buttonData={button}
            tabIndex={0}
          />
        </li>
      </ul>
    </>
  );
};

ButtonTemplate.Menu2Button = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;

  let menuContainerStyle = {
    display: "flex",
    position: "absolute",
    bottom: "5%",
    width: "100%",
    height: "34%",
    flexWrap: "wrap",
    flexDirection: "row",
  };

  return (
    <>
      {backButtonTarget && backButtonTarget.length && (
        <VIEButton
          id="BackButton"
          style={backButtonStyle}
          aria-label={"go back"}
          onButtonClick={onButtonClick}
          buttonData={{
            target: {
              chapters: backButtonTarget,
            },
          }}
        />
      )}
      <nav style={menuContainerStyle}>
        {buttons.map((button, idx) => {
          return (
            <VIEButton
              id={`Button${idx + 1}`}
              className="two"
              key={`button-${idx + 1}`}
              onButtonClick={onButtonClick}
              buttonData={button}
              tabIndex={0}
            />
          );
        })}
      </nav>
    </>
  );
};

ButtonTemplate.Menu4Button = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;

  let buttonStyle = {
    height: "50%",
    width: "50%",
  };

  let menuContainerStyle = {
    display: "flex",
    position: "absolute",
    bottom: "13.5%",
    width: "100%",
    height: "34%",
    flexWrap: "wrap",
    flexDirection: "row",
  };
  return (
    <>
      {backButtonTarget && backButtonTarget.length && (
        <VIEButton
          id="BackButton"
          style={backButtonStyle}
          onButtonClick={onButtonClick}
          aria-label={"go back"}
          buttonData={{
            target: {
              chapters: backButtonTarget,
            },
          }}
        />
      )}
      <nav style={menuContainerStyle}>
        {buttons.map((button, idx) => {
          return (
            <VIEButton
              id={`Button${idx + 1}`}
              style={buttonStyle}
              key={`button-${idx + 1}`}
              onButtonClick={onButtonClick}
              buttonData={button}
              tabIndex={0}
            >
              {button.label}
            </VIEButton>
          );
        })}
      </nav>
    </>
  );
};

ButtonTemplate.Conclusion2Button = (props) => {
  const { onButtonClick, buttons } = props;

  const conclusionMenuStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    height: "18.25%",
    bottom: "10%",
  };

  const menuButtonStyle = {
    width: "60%",
    height: "50%",
  };

  return (
    <>
      <nav style={conclusionMenuStyle}>
        {buttons.map((button, idx) => {
          return (
            <VIEButton
              id={`Button${idx + 1}`}
              style={menuButtonStyle}
              key={`button-${idx + 1}`}
              buttonData={button}
              onButtonClick={onButtonClick}
              tabIndex={0}
            >
              {button.label}
            </VIEButton>
          );
        })}
      </nav>
    </>
  );
};

ButtonTemplate.Splash1Button = (props) => {
  const { onButtonClick, buttons, skipButtonTarget } = props;

  /* const conclusionMenuStyle = {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  };

  const menuButtonStyle = {
    width: "60%",
    height: "50%",
  };
*/
  return (
    <ButtonTemplate.Default skipButtonTarget={skipButtonTarget}>
      <nav
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <VIEButton
          id={`Button1`}
          style={{
            position: "absolute",
            top: "43.5%",
            left: "51%",
            transform: "translateX(-50%)",
            width: "39%",
            height: "8%",
          }}
          key={`button-1`}
          buttonData={buttons[0]}
          onButtonClick={onButtonClick}
        />
      </nav>
    </ButtonTemplate.Default>
  );
};

ButtonTemplate.EndOfGame2Button = (props) => {
  const { onButtonClick, buttons } = props;

  const conclusionMenuStyle = {
    position: "absolute",
    bottom: "15%",
    left: "50%",
    translate: "-50% 0",
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
  };

  // const menuButtonStyle = {
  //   width: "60%",
  //   height: "50%",
  // };

  return (
    <>
      <nav style={conclusionMenuStyle}>
        <VIEButton
          id={`Button1`}
          style={{
            position: "absolute",
            bottom: "11%",
            left: "12%",
            height: "7%",
            width: "36%",
          }}
          idx={0}
          isCTAbtn
          className="cta-btn--image"
          key={`button-1`}
          buttonData={buttons[0]}
          onButtonClick={onButtonClick}
          tabIndex={0}
        >
          {buttons[0].label}
        </VIEButton>
        {/* <VIEButton
          id={`Button2`}
          style={{
            position: "absolute",
            bottom: "11%",
            right: "10%",
            height: "7%",
            width: "36%",
          }}
          idx={0}
          isCTAbtn
          className="cta-btn--image"
          key={`button-2`}
          buttonData={buttons[1]}
          onButtonClick={onButtonClick}
          tabIndex={0}
        >
          {buttons[1].label}
        </VIEButton> */}
      </nav>
    </>
  );
};

const EndOfGameTextAnimation = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;
  const setGameLevel = useStore((state) => state.setGameLevel);
  const currentPage = useStore((state) => state.currentPage);

  let menuContainerStyle = {
    display: "flex",
    position: "absolute",
    top: "0",
    width: "98vw",
    height: "98vh",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "1vh",
  };

  let buttonStyle = {};

  return (
    <>
      <ResultsFeedback animFlag="animated" />
      <ul className={"cta-btn-list"}>
        {buttons.map((button, idx) => {
          return (
            button &&
            button?.target?.chapters?.length && (
              <li>
                <VIEButton
                  isCTAbtn
                  idx={idx}
                  id={`Button${idx + 1}`}
                  key={`button-${idx + 1}`}
                  onButtonClick={onButtonClick}
                  buttonData={button}
                  tabIndex={0}
                  style={{
                    flexGrow: 1,
                  }}
                />
              </li>
            )
          );
        })}
      </ul>
      {/* </nav> */}
    </>
  );
};
ButtonTemplate.EndOfGameTextAnimation = EndOfGameTextAnimation;

const FullScreenHoriz2Button = (props) => {
  const { onButtonClick, buttons, backButtonTarget } = props;
  const setGameLevel = useStore((state) => state.setGameLevel);
  const currentPage = useStore((state) => state.currentPage);

  let menuContainerStyle = {
    display: "flex",
    position: "absolute",
    top: "0",
    width: "98vw",
    height: "98vh",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "1vh",
  };

  let buttonStyle = {};

  return (
    <>
      <ResultsFeedback animFlag="" />
      <ul className="cta-btn-list" style={{ top: "72%" }}>
        {buttons.map((button, idx) => {
          return (
            button &&
            button?.target?.chapters?.length && (
              <li>
                <VIEButton
                  isCTAbtn
                  idx={idx}
                  id={`Button${idx + 1}`}
                  key={`button-${idx + 1}`}
                  onButtonClick={onButtonClick}
                  buttonData={button}
                  tabIndex={0}
                  style={{
                    flexGrow: 1,
                  }}
                />
              </li>
            )
          );
        })}
      </ul>
      {/* </nav> */}
    </>
  );
};

ButtonTemplate.FullScreenHoriz2Button = FullScreenHoriz2Button;

const getSuccessAnim = (id) => {
  //console.log("getSuccessAnim", id);
  if (id.includes("red")) {
    return "HIT_SUCCESS_PURPLE";
  } else if (id.includes("blue")) {
    return `HIT_SUCCESS_BLUE`;
  } else if (id.includes("yellow")) {
    return `HIT_SUCCESS_YELLOW`;
  } else if (id === "hit_streak") {
    return "HIT_STREAK";
  }
  return false;
};

const BeatGame1Button = (props) => {
  const {
    onButtonClick,
    buttons,
    addOverlayAnimation,
    score,
    setScore,
    id,
    beatsList,
    streakCount,
    setStreakCount,
    lastHit,
    setLastHit,
    hitsCount,
    setHitsCount,
  } = props;

  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  /*const GlassBreakSound = useRef(null);
  if (descriptionsEnabled) {
    GlassBreakSound.current = new Audio();
    GlassBreakSound.current.src = "static/media/global/audio/glass-break-sharp.wav";
    GlassBreakSound.current.preload = "auto";
    GlassBreakSound.current.volume = 0.1;
  }*/

  let containerStyle = {
    display: "flex",
    position: "absolute",
    bottom: "14%",
    width: "100%",
    height: "34%",
    flexWrap: "wrap",
    flexDirection: "row",
    opacity: 0.5,
  };
  let button = buttons[0];

  /*useEffect(() => {});*/
  const showStreakAnimation = (currentStreakCount) => {
    if (
      currentStreakCount === 10 ||
      currentStreakCount === 25 ||
      currentStreakCount === 50 ||
      currentStreakCount === 75 ||
      currentStreakCount === 100 ||
      currentStreakCount === 125 ||
      currentStreakCount === 150 ||
      currentStreakCount === 175 ||
      currentStreakCount === 200
    ) {
      addOverlayAnimation({
        name: getSuccessAnim("hit_streak"),
        var_x: currentStreakCount,
        action: (jsonData) => {
          if (jsonData.nm === "callout_150x_v002_export") {
            jsonData.layers.forEach((layer) => {
              if (layer.nm.toLowerCase().indexOf("150x") !== -1) {
                layer.t.d.k[0].s.t = `${currentStreakCount}X`;
              }
            });
          }
        },
      });
    }
  };

  const onBeatHit = () => {
    //console.log(`${id} Orb Hit!!!!!!!!`);

    if (lastHit !== id) {
      if (descriptionsEnabled) {
        //console.log("playing audio for orb hit");
        //GlassBreakSound.current.play();
      }

      const animationName = getSuccessAnim(id);
      const indexOfBeat = beatsList.indexOf(id);

      if (animationName) addOverlayAnimation({ name: getSuccessAnim(id) });
      if (button.animation) addOverlayAnimation({ name: button.animation });
      setScore(score + 100);
      setHitsCount(hitsCount + 1);

      const newStreakCount =
        lastHit === beatsList[indexOfBeat - 1] ? streakCount + 1 : 1;
      //console.log("onBeatHit :: HIT! UPDATING STREAK COUNT :: streakCount = " + newStreakCount);
      setStreakCount(newStreakCount);
      showStreakAnimation(newStreakCount);
      /*} else {
        console.log("onBeatHit :: MISS! RESETING STREAK COUNT :: ", { id, lastHit, indexOfBeat, beatsList });
        setStreakCount(1);
      }*/
      //console.log("onBeatHit :: SETTING LAST HIT :: id=" + id);
      setLastHit(id);
    }
  };

  return (
    <nav style={containerStyle}>
      <VIEButton
        id="Button1"
        className="beat-trigger no-outline"
        onKeyDown={(e) => {
          if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
            e.stopPropagation();
            e.target.onkeydown = null;
            onBeatHit();
            onButtonClick(button.target.chapters);
            //e.target.disabled = true;
            //onButtonClick();
          }
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          e.target.ontouchstart = null;
          onBeatHit();
          onButtonClick(button.target.chapters);
        }}
        onButtonClick={onButtonClick}
        buttonData={button}
        autoFocus={true}
        aria-hidden={"true"}
      />
    </nav>
  );
};

ButtonTemplate.BeatGame1Button = BeatGame1Button;

ButtonTemplate.Default = (props) => {
  const { children } = props;

  return <>{children}</>;
};

export default ButtonTemplate;
