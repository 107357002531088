import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useStore } from '@tmsw/powerups.store';
import { useTranslate } from '@tmsw/powerups.intl.legacy.hooks.use-translate';
import { useAoContext, useAoStateContext, wrapAoProvider } from './context';
import createAnimation from './factory/createAnimation';
import LottiePlayer from './Players/LottiePlayer';

const componentMap = {
  LOTTIE: LottiePlayer,
};

function AnimationInline({ refPassthrough, onComplete, onLoopComplete, ...props }) {
  console.log(refPassthrough, 'REFPASS');
  // non-context state;
  // monkey-patch until have new translation methods from bit repo
  const __t = useTranslate();
  const translate = (str) => {
    return __t(str, 'ANIMATION');
  };

  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  const ref = useRef();

  /**
   * Overload the user supplied ref passthrough
   */
  props.refPassthrough = (el) => {
    ref.current = el.current;
    console.log('ELCURRENTANIMATIONINLINE' + el.current);
    if (refPassthrough) {
      refPassthrough(el);
    }
  };

  const { aoRemove } = useAoContext();
  const state = useAoStateContext();
  const action = {
    payload: props,
    translate,
    descriptionsEnabled,
  };

  const animation = createAnimation(state, action);
  animation.onComplete = () => {
    if (onComplete) {
      onComplete();
    }
    aoRemove({ hash: animation.hash });
  };  

  animation.onLoopComplete = () => {
    if (onLoopComplete) {
      onLoopComplete();
    }
  };
  
  animation.dispose = () => {
    if (ref.current) {
      ref.current.destroy();
    }
    animation.onComplete();
  };

  const PlayerComponent = componentMap[animation.type];

  useEffect(() => {
    return function cleanup() {
      animation.dispose();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return <PlayerComponent key={animation.hash} {...animation} />;
}

AnimationInline.defaultProps = {};

AnimationInline.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  ariaDescription: PropTypes.string,
  ariaLabel: PropTypes.string,
  audioDescription: PropTypes.string,
  hash: PropTypes.string,
  loop: PropTypes.bool,
  speed: PropTypes.number,
  onComplete: PropTypes.func,
  onLoopComplete: PropTypes.func,
  refPassthrough: PropTypes.func,
  unique: PropTypes.bool,
  segments: PropTypes.any,
};

const AnimationInlineHoc = wrapAoProvider(AnimationInline);
AnimationInlineHoc.displayName = 'AnimationInlineHoc';

export default AnimationInlineHoc;
