import fastForwardIcon from "./Button-FastForward.svg";
import { useStore } from "@tmsw/powerups.store";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import canPlayAudio from "../Audio/util/canPlayAudio";
import CircleButton from "../CircleButton";
import "./SkipButton.css";
import GAME_PAGES from "../../constants/GAME_PAGES";
import AudioDescriptions from "../AudioDescriptions";

export const SkipButton = (props) => {
  const skipTarget = useStore((state) => state.skipTarget);
  const setShowSkip = useStore((state) => state.setShowSkip);
  const translate = useTranslate();
  const isPlaying = useStore((state) => state.isPlaying);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const setIsPauseEnabled = useStore((state) => state.setIsPauseEnabled);
  const skipButtonLocale = translate("skipButton", "GENERAL");
  const page = useStore((state) => state.currentPage);

  const styles = {
    zIndex: "90",
    padding: 0,
    display: "block",
  };

  return (skipTarget || AudioDescriptions.speaking) &&
    (isPauseEnabled || isPlaying || AudioDescriptions.speaking) &&
    (page === GAME_PAGES.SPLASH || page === GAME_PAGES.GAME_PLAY) ? (
    <CircleButton
      variant='dark'
      icon={"skip"}
      style={styles}
      ariaLabel={skipButtonLocale.ariaLabel}
      onClick={(e) => {
        const canPlay = canPlayAudio();
        console.log("canPlayAudio", canPlay);
        // if (!canPlay) {
        //   console.trace(
        //     "SkipButton cannot be clicked until the audio context is started...",
        //     { props }
        //   );
        //   return;
        // }
        if(!AudioDescriptions.speaking) {
            setShowSkip(false);
            if (props.onClick) props.onClick(e);
            if (!isPlaying) setIsPauseEnabled(false); // Only when video is paused, clicking the Skip button will set IsPauseEnabled to False
        } else {
            AudioDescriptions.resetSpeech();
        }
      }}
    />
  ) : // <button
  //   id="SkipButton"
  //   style={skipButtonStyle}
  //   aria-label={skipButtonLocale.ariaLabel}
  //   onClick={(e) => {
  //     const canPlay = canPlayAudio();
  //     console.log('canPlayAudio', canPlay);
  //     if(!canPlay) {
  //       console.trace('SkipButton cannot be clicked until the audio context is started...', { props });
  //       return;
  //     }
  //     setShowSkip(false);
  //     if (props.onClick) props.onClick(e);
  //   }}
  //   {...props}
  // >
  //   <img
  //     title={skipButtonLocale.title}
  //     alt={skipButtonLocale.alt}
  //     src={fastForwardIcon}
  //     style={{ width: "100%", height: "100%", display: "block" }}
  //   />
  // </button>
  null;
};

export default SkipButton;
