import { useEffect, useRef } from 'react';
// import PropTypes from "prop-types";
import { useTranslation } from '@tmsw/powerups.intl.hooks.use-translation';
import { useStoreApi, useStore } from "@tmsw/powerups.store";
import AudioDescriptions from "./index";

const descriptionsEnabledSelector = (state:any) => state.descriptionsEnabled;

function DescriptionsPlayback() {
  const { __t } = useTranslation();
  const { subscribe, getState } = useStoreApi();
  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  const audioDescriptionTranslate = __t('descriptionsToggle', 'A11Y_MENU');
  const currentDescriptionRef = useRef(useStore((state) => state.currentDescription));
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const isPauseMenuDisplayed = useStore((state) => state.isPauseMenuDisplayed);


  useEffect(() => {
    const descEnabledUnsub = subscribe(descriptionsEnabledSelector, (isEnabled:boolean, prevIsEnabled:boolean) => {
      if (!AudioDescriptions.isUsingSpeechAPI && isEnabled) {
        alert('Audio Descriptions require accessibility technology enabled in your device settings');
      }
      if(showSettingsPanel) AudioDescriptions.cancel();
      AudioDescriptions.speak(`${!isEnabled ? audioDescriptionTranslate?.off?.label : audioDescriptionTranslate?.on?.label}`, false, true);
      /*if (!(showSettingsPanel || isPauseMenuDisplayed)) {
        AudioDescriptions.resume();
      }*/
      //AudioDescriptions.resume();
    });
    return () => {
      descEnabledUnsub();
    };
  }, [subscribe, audioDescriptionTranslate, ]);

  useEffect(() => {
    const descChangeUnsub = subscribe(
      (state:any) => state.currentDescription,
      (curr:any) => {
        currentDescriptionRef.current = curr;
      }
    );
    return () => {
      descChangeUnsub();
    };
  }, [subscribe]);

  return descriptionsEnabled ? (
    <div aria-live={'assertive'} role={'status'} style={{ opacity: 0, pointerEvents: 'none', position: 'absolute' }}>
      {currentDescriptionRef.current}
    </div>
  ) : (
    <></>
  );
}

DescriptionsPlayback.whyDidYouRender = true;

export default DescriptionsPlayback;
