import { useCallback } from "react";
import { useStore } from "@tmsw/powerups.store";

const useTextOverlayCueChange = () => {
  const setCurrentTextOverlay = useStore((state) => state.setCurrentTextOverlay);

  const onCueChange = useCallback(
    (event) => {
      console.log("useTextOverlayCueChange :: onCueChange", event);

      var overlayText = "";
      for (var j = 0; j < event.currentTarget.activeCues.length; j++) {
        overlayText = event.currentTarget.activeCues[j].text;
      }
      setCurrentTextOverlay(overlayText);
    },
    [setCurrentTextOverlay]
  );

  return onCueChange;
};

export default useTextOverlayCueChange;
