import React from 'react';
import PropTypes from 'prop-types';
import {
  DotMenuDark,
  VolumeDark,
  PauseDark,
  BackDark,
  CloseDark,
  SkipDark,
  PlayDark,
  AccessibilityDark,
} from './dark';
import {
  DotMenuWhite,
  PauseWhite,
  SkipWhite,
  PlayWhite,
  ReplayWhite,
  ConfirmWhite
} from './white';
import { useStore } from '@tmsw/powerups.store';
import './CircleButton.css';
import './CircleButton-UI.css';

const variantMap = {
  dark: {
    dots: DotMenuDark,
    volume: VolumeDark,
    pause: PauseDark,
    back: BackDark,
    close: CloseDark,
    skip: SkipDark,
    play: PlayDark,
    accessibility: AccessibilityDark,
  },
  white: {
    dots: DotMenuWhite,
    pause: PauseWhite,
    skip: SkipWhite,
    play: PlayWhite,
    replay: ReplayWhite,
    confirm: ConfirmWhite,
    back: BackDark,
    close: CloseDark,
  },
};

const CircleButton = React.forwardRef((props, ref) => {
  const { icon, ariaLabel, variant, delay, onClick, ...rest } = props;
  const currentPage = useStore((state) => state.currentPage);
  let overrideVariant = currentPage === 2 ? 'white' : 'white';
  const VariantComponent = variantMap[overrideVariant][icon];
  const labelId = `CircleButton--${overrideVariant}--${icon}`;
  const handleClick = delay
    ? (e) => setTimeout(() => onClick(e), delay)
    : onClick;

  return (
    <button
      ref={ref}
      data-function={icon}
			className={`CircleButton CircleButton--${overrideVariant} CircleButton--${icon} ui global core svgMasked circle ${overrideVariant} selectable`}
      aria-labelledby={labelId}
      onClick={handleClick}
      {...rest}
    >
      <span id={labelId} hidden>
        {ariaLabel}
      </span>
      <VariantComponent aria-hidden="true" focusable="false" />
    </button>
  );
});

CircleButton.defaultProps = {
  variant: 'white',
  delay: 0,
  onClick: () => {},
};

CircleButton.propTypes = {
  icon: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default CircleButton;
