import { useEffect, useState, useRef } from "react";
import { useStore } from "@tmsw/powerups.store";
// import { useLocalizedImg } from '@tmsw/powerups.intl.hooks.use-localized-img';
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
import { useTranslation } from "@tmsw/powerups.intl.hooks.use-translation";
import { useVieConfig } from "@tmsw/powerups.vie.hooks.use-vie-config";
import { VIEButton } from "../../components/Templates/VIE";
import { useSkipChapter } from "@tmsw/powerups.vie.hooks.use-skip-chapter";
import useResetFocus from "../../hooks/useResetFocus";
import { useLocalizedImg } from "@tmsw/powerups.intl.hooks.use-localized-img";
import "./Splash.css";

function Splash() {
  const translate = useTranslate();
  const parentNode = "SPLASH";
  const { __t } = useTranslation(parentNode);
  const skipChapter = useSkipChapter();
  const letsPlayBtn = __t("playButton");
  const logoAlt = __t("logoImageAlt");
  const translatedConfig = __t("vieConfig");
  const { vieConfigRef, updateVieConfig } = useVieConfig();
  const logoPng = useLocalizedImg(__t("logoImg"), "img");
  const { goToNextPage } = useVieConfig();
  const pageTitle = translate("pageTitle", parentNode);
  const setPageTitle = useStore((state) => state.setPageTitle);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const inPortrait = useStore((state) => state.inPortrait);
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const currentCueMetadata = useStore((state) => state.currentCueMetadata);
  const [splashVideoHeaderVisible, setSplashVideoHeaderVisible] =
    useState(false);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [showSettingsPanel, inPortrait, isPauseEnabled]);

  useResetFocus([showSettingsPanel, inPortrait, isPauseEnabled, skipChapter]);

  const prevCueMetadataId = useRef();

  useEffect(() => {
    const currentId = currentCueMetadata?.id;
    if (currentId !== undefined && prevCueMetadataId.current !== currentId) {
      prevCueMetadataId.current = currentId;

      if (currentId === "TRIGGER_CONTENT_IN" || currentId === "CHAPTER_HOLD") {
        if (
          !splashVideoHeaderVisible ||
          prevCueMetadataId.current === undefined
        ) {
          setSplashVideoHeaderVisible(true);
        }
      } else {
        setSplashVideoHeaderVisible(false);
      }
    }
  }, [currentCueMetadata?.id, splashVideoHeaderVisible]);

  const templates = {
    "CaptA - Intro - 1 button": (props) => {
      const { onButtonClick, buttons } = props;
      return (
        <>
          <VIEButton
            id='Button1'
            className={"primary-cta-btn cta-btn splash-cta"}
            buttonData={{
              ...buttons[0],
              label: letsPlayBtn.label,
              ariaLabel: letsPlayBtn.ariaLabel,
            }}
            onButtonClick={onButtonClick}
          />
        </>
      );
    },
  };

  // initial load on mount
  useEffect(() => {
    updateVieConfig({
      ...translatedConfig,
      skipChapter: skipChapter,
      templates: templates,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // custom update
  useEffect(() => {
    if (skipChapter !== vieConfigRef.current.skipChapter) {
      updateVieConfig({ skipChapter });
    }
  }, [skipChapter, updateVieConfig, vieConfigRef]);

  return (
    <>
      {splashVideoHeaderVisible && (
        <div className='splash-content fade-in'>
          {/* The logo image is added for accessibility purpose due to the actual logo embedded in the video */}
          <img src={logoPng} alt={logoAlt} className='splash-logo-img' />
          <h1 className='splash-h1 title'>{__t("h1", parentNode)}</h1>
          {/* <p className="splash-p subtitle">{__t("bodyCopy", parentNode)}</p> */}
        </div>
      )}
    </>
  );
}
// Splash.whyDidYouRender = true;

export default Splash;
