import { useRef, useEffect } from "react";
import { useStore, useStoreApi } from '@tmsw/powerups.store';

function TextOverlay() {
  const { subscribe } = useStoreApi();
  const currentTextOverlayRef = useRef();

  useEffect(() => {
    const overlayTextChangeUnsub = subscribe(
      (state) => state.currentTextOverlay,
      (curr) => {
        //console.log("TextOverlay :: new currentTextOverlay", curr);
        currentTextOverlayRef.current = curr;
      }
    );
    return () => {
      overlayTextChangeUnsub();
    };
  }, [subscribe]);

  return (
    <div
      id={"Text-Overlay"}
      style={{
        position: "fixed",
        top: "9.5%",
        right: "2.5vh",
        fontSize: "1.125vh",
        textShadow: "1px 1px 1px black",
        width: "100%",
        color: "white",
        textAlign: "right",
      }}
    >
      {currentTextOverlayRef.current}
    </div>
  );
}

export default TextOverlay;
