import { VolumeControls } from "@tmsw/powerups.audio.components.volume-controls";
import { OptionsMenu } from "@tmsw/powerups.ui.dialogs.settings";
import AccessibilityMenu from "./AccessibilityMenu";
import Controls from "./controls";
import { isIOS } from "./../hooks/isIOS";

export const SettingsPanelConfig = {
  root: {
    name: "OptionsMenu",
    variant: "panel",
    heading: { localeKey: "openSettingsPanel", ns: "SETTINGS_PANEL" },
    component: OptionsMenu,
    options: {},
    items: [
      {
        name: "AccessibilitySettings",
        variant: "panel",
        heading: { localeKey: "accessibilitySettings", ns: "SETTINGS_PANEL" },
        underline: false,
        component: AccessibilityMenu,
      },
      {
        name: "KeyboardControls",
        variant: "panel",
        heading: { localeKey: "gameControls", ns: "SETTINGS_PANEL" },
        underline: false,
        component: Controls,
        className: "testing-panel-item-classname withChevron",
      },
      {
        name: "VolumeControls",
        variant: "widget",
        heading: { localeKey: "volumeSetting", ns: "SETTINGS_PANEL" },
        component: VolumeControls,
        shouldRender: (props: any) => !isIOS(17),
      },
      {
        name: "HappyMealHome",
        variant: "link",
        heading: { localeKey: "returnToHappyMealDotCom", ns: "SETTINGS_PANEL" },
        href: { localeKey: "hmButton.url", ns: "DISCLAIMER" },
        underline: true,
        className: "withChevron withUnderline",
        shouldRender: (props: any) => props.returnToHappyMealDotCom !== "",
      },
      {
        name: "AppHome",
        heading: { localeKey: "appHomePageLink", ns: "SETTINGS_PANEL" },
        variant: "goto",
        targetPage: 0,
        underline: true,
        className: "withChevron withUnderline",
      },
      // {
      //   name: "SelectCharacter",
      //   heading: { localeKey: "levelSelection", ns: "SETTINGS_PANEL" },
      //   variant: "goto",
      //   targetPage: "LEVEL_SELECTION",
      //   underline: true,
      //   shouldRender: (props: any) => !props.cameraAccessConsented,
      // },
      // {
      //   name: "CameraAccess",
      //   heading: { localeKey: "cameraAccess", ns: "SETTINGS_PANEL" },
      //   variant: "goto",
      //   targetPage: "CAMERA_ACCESS",
      //   underline: true,
      // },
      // {
      //   name: "GameInstructions",
      //   heading: { localeKey: "gameInstructions", ns: "SETTINGS_PANEL" },
      //   variant: "goto",
      //   targetPage: "GAME_INSTRUCTIONS",
      //   underline: true,
      //   shouldRender: (props: any) => !props.cameraAccessConsented,
      // },
    ],
  },
};

export const componentConfig = {
  CircleButton: {
    defaultVariant: "gradient",
    defaultColor: "primary",
    defaultSize: "md",
  },
  SettingsPanel: SettingsPanelConfig,
};
