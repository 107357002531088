const SvgSkip = (props) => (
  <svg width="120" height="120" viewBox="0 0 128 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117 60.5C117 92.2799 91.4568 118 60 118C28.5432 118 3 92.2799 3 60.5C3 28.7201 28.5432 3 60 3C91.4568 3 117 28.7201 117 60.5Z" fill="url(#gradient)" stroke="url(#gradient-stroke)" strokeWidth="8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M87 39C87 36.2386 84.7614 34 82 34C79.2386 34 77 36.2386 77 39V82C77 84.7614 79.2386 87 82 87C84.7614 87 87 84.7614 87 82V39ZM44.6236 35.9126L70.4743 56.831V56.8529C72.4822 58.4788 72.4822 61.545 70.4743 63.1709L44.6236 84.0894C41.9611 86.2391 38 84.3404 38 80.9249V39.0771C38 35.6507 41.9611 33.7629 44.6236 35.9126Z" fill="url(#gradient-pause-bars)"/>
  
    <defs>
    <linearGradient id="gradient" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#B70700" />
      <stop offset="100%" stopColor="#5A1815" />
    </linearGradient>

    <linearGradient id="gradient-stroke" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#FCE685" />
      <stop offset="20%" stopColor="#EBB261" />
      <stop offset="50%" stopColor="#FEF08B" />
      <stop offset="80%" stopColor="#EAAA56" />
      <stop offset="100%" stopColor="#F1B02D" />
    </linearGradient>

    <linearGradient id="gradient-pause-bars" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#FCE685" />
      <stop offset="100%" stopColor="#F1B02D" />
    </linearGradient>
  </defs>
  
  </svg>
);

export default SvgSkip;
