import Disclaimer from "./Disclaimer/Disclaimer";
import Splash from "./Splash/Splash";
// import LevelSelection from "./LevelSelection";
// import HowToPlay from "./HowToPlay/HowToPlay";
import GamePlay from "./GamePlay/GamePlay";
// import GameResults from "./GameResults/GameResults";

export const Pages = {
  Disclaimer,
  Splash,
  // LevelSelection,
  // HowToPlay,
  GamePlay,
  // GameResults,
};
