// @ts-nocheck
import './wdyr'; // <--- first import
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

console.log(`%c${process.env.REACT_APP_NAME}`,"color:#08ccda;font-family:sans-serif;font-size:36px");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);