import PropTypes from 'prop-types';
import { useTranslate } from '@tmsw/powerups.intl.legacy.hooks.use-translate';
import './CTAButton.css';

const CTAButton = (props) => {
  const {
    onClick,
    primary,
    intlKey,
    style,
    parentNode,
    className,
    ariaLabel,
    label,
    role,
  } = props;
  const translate = useTranslate();
  const parentKey = parentNode || 'PAUSE_MENU';
  const btnClassName = primary ? 'primary-cta-btn' : 'secondary-cta-btn';
  const intlValues = translate(intlKey, parentKey);
  return (
    <button
      role={role}
      className={`${btnClassName} cta-btn ${className}`}
      aria-label={intlValues.ariaLabel || ariaLabel}
      onClick={onClick}
      style={{ ...style }}
    >
      <span className="cta-btn-txt">
        {translate(intlKey, parentKey).label || label}
      </span>
    </button>
  );
};

CTAButton.defaultProps = {
  primary: false,
  onClick: () => {},
};

CTAButton.propTypes = {
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CTAButton;
