import { useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useStore, useStoreApi } from "@tmsw/powerups.store";
import useDescriptionsCueChange from "./hooks/useDescriptionsCueChange";
import useTextOverlayCueChange from "./hooks/useTextOverlayCueChange";

function InterfacePlayer(props) {
  const {
    videoRef,
    src,
    tracks,
    onCanPlayThrough,
    onSeeked,
    onSeeking,
    onPlayCapture,
    onPlay,
    onPauseCapture,
    onPause,
    onLoad,
    poster,
    ...videoProps
  } = props;

  // console.log('InterfacePlayer', props, videoRef);

  const { subscribe } = useStoreApi();
  const captionsEnabled = useStore((state) => state.captionsEnabled);
  const descriptionsEnabled = useStore((state) => state.descriptionsEnabled);
  const locale = useStore((state) => state.locale) || "en-US";

  const descHandlerRef = useRef();

  const defaultLoaderAnimPath = `${process.env.PUBLIC_URL}/static/media/${locale}/anim`;
  const ApplesGif = `${defaultLoaderAnimPath}/apples.gif`;
  const CarrotsGif = `${defaultLoaderAnimPath}/carrots.gif`;
  const PineappleGif = `${defaultLoaderAnimPath}/pineapple.gif`;
  const loaderGifs = useMemo(
    () => [ApplesGif, CarrotsGif, PineappleGif][Math.floor(Math.random() * 3)],
    [src]
  );

  const onDescriptionCueChange = useDescriptionsCueChange(videoRef?.current);
  const onTextOverlayCueChange = useTextOverlayCueChange();

  /**
   * @name initializeTextOverlayTracks
   *
   * Called on video onLoad
   *
   */
  const initializeTextOverlayTracks = useCallback(() => {
    for (let j = 0; j < videoRef.current.textTracks.length; j++) {
      if (videoRef.current.textTracks[j].label === "TextOverlayTrack") {
        videoRef.current.textTracks[j].default = true;
        videoRef.current.textTracks[j].mode = "showing";
        videoRef.current.textTracks[j].addEventListener("cuechange", videoRef.current.onTextOverlayCueChange, {
          passive: true,
        });
      }
    }
  }, [videoRef]);

  const showAllTextTracks = useCallback(() => {
    let track;
    for (let i = 0; i < videoRef.current.textTracks.length; i++) {
      track = videoRef.current.textTracks[i];
      track.mode = "showing";
    }
  }, [videoRef]);

  /**
   * @name resetTextTracks
   *
   * Called
   */
  const resetTextTracks = useCallback(
    (tracksToReset) => {
      //console.log("RESETTING TRACKS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      let reset = tracksToReset || {
        chapters: false,
        metadata: {
          PowerUpMetadata: false,
          PowerUpBeatADMetadata: false,
          TextOverlayTrack: false,
        },
        captions: {
          PowerUpCaptions: false,
        },
        descriptions: {
          PowerUpADs: false,
        },
        subtitles: {},
      };

      let result = {
        chapters: null,
        metadata: null,
        captions: null,
        descriptions: null,
        subtitles: null,
      };

      for (let i = videoRef.current.textTracks.length - 1; i >= 0; i--) {
        let currTrack = videoRef.current.textTracks[i];
        //if (currTrack.label !== "TextOverlayTrack") {
        videoRef.current.textTracks[i].mode = "disabled";
        if (videoRef.current.textTracks[i].label)
          videoRef.current.textTracks[i].name = videoRef.current.textTracks[i].label;
        //console.log("currTrack", currTrack);
        if (!reset[currTrack.kind][currTrack.name]) {
          if (
            (currTrack.name === "PowerUpCaptions" && captionsEnabled) ||
            (currTrack.name === "PowerUpADs" && descriptionsEnabled) ||
            (currTrack.name === "PowerUpBeatADMetadata" && descriptionsEnabled) ||
            currTrack.name === "PowerUpMetadata" ||
            currTrack.name === "TextOverlayTrack" ||
            currTrack.kind === "chapters"
          ) {
            videoRef.current.textTracks[i].mode = "showing";
          }
          reset[currTrack.kind][currTrack.name] = true;
          result[currTrack.kind] = currTrack;
        }
      }
      //console.log("DONE RESETTING TEXT TRACKS", videoRef.current.textTracks);
      console.log("Trying to reenable ADs")
      enableDescriptionHandler(true)
      enableDescriptionHandler()
      return result;
    },
    [captionsEnabled, descriptionsEnabled, videoRef]
  );

  const isMetadataAvailable = useCallback(() => {
    const trackArray = [...videoRef.current.textTracks];
    const activeTracks = trackArray.filter((track) => track.name === "PowerUpMetadata" && track.mode !== "disabled");
    return !!activeTracks?.length;
  }, [videoRef]);

  /**
   * @name onAfterLoadMetadata
   */
  const onAfterLoadMetadata = useCallback(() => {
    /*videoRef.current.showAllTextTracks();
    videoRef.current.resetTextTracks();
    videoRef.current.play();*/
  }, []);

  const enableDescriptionHandler = useCallback(
    (disable) => {
      console.log ("Cue: Enable Descriptions Handler")
      if (videoRef.current.textTracks.length === 0) return;

      for (let i = videoRef.current.textTracks.length - 1; i >= 0; i--) {
        if (videoRef.current.textTracks[i].kind === "descriptions") {
          if (!disable && !descHandlerRef.current) {
            // this.setState({ descriptionsTrack: videoRef.current.textTracks[i] }, function () {
            ///videoRef.current.resetTextTracks();
            descHandlerRef.current = true;
            videoRef.current.textTracks[i].oncuechange = (evt) => videoRef.current.onDescriptionCueChange(evt)
            console.log("adding AD Cue handler")
            // });
          } else {
            // this.setState({ descriptionsTrack: null }, () => {
            descHandlerRef.current = false;
            videoRef.current.textTracks[i].oncuechange = null;
            console.log("removing AD Cue handler")
            //videoRef.current.resetTextTracks();
            // });
          }
        }
      }
    },
    [videoRef, descHandlerRef.current]
  );

  // on mount
  useEffect(() => {
    videoRef.current.initializeTextOverlayTracks = initializeTextOverlayTracks;
    videoRef.current.showAllTextTracks = showAllTextTracks;
    videoRef.current.resetTextTracks = resetTextTracks;
    videoRef.current.onAfterLoadMetadata = onAfterLoadMetadata;
    videoRef.current.onDescriptionCueChange = onDescriptionCueChange;
    videoRef.current.onTextOverlayCueChange = onTextOverlayCueChange;
    videoRef.current.enableDescriptionHandler = enableDescriptionHandler;
    videoRef.current.isMetadataAvailable = isMetadataAvailable;
  }, [
    enableDescriptionHandler,
    initializeTextOverlayTracks,
    onAfterLoadMetadata,
    onDescriptionCueChange,
    onTextOverlayCueChange,
    resetTextTracks,
    showAllTextTracks,
    videoRef,
    isMetadataAvailable,
  ]);

  /**
   * OnChange descriptionsEnabled
   */
  useEffect(() => {
    const descEnabledUnsub = subscribe(
      (state) => state.descriptionsEnabled,
      (isEnabled, prevIsEnabled) => {
        /*console.log(
          "InterfacePlayer :: useEffect :: descEnabledUnsub :: enableDescriptionHandler",
          isEnabled,
          prevIsEnabled
        );*/
        videoRef.current.enableDescriptionHandler(!isEnabled);
      }
    );
    return () => {
      descEnabledUnsub();
    };
  }, [subscribe, videoRef]);

  /**
   * OnChange captionsEnabled
   */
  useEffect(() => {
    const capEnabledUnsub = subscribe(
      (state) => state.captionsEnabled,
      (isEnabled, prevIsEnabled) => {
        //console.log("InterfacePlayer :: useEffect :: capEnabledUnsub", isEnabled, prevIsEnabled);
        //videoRef.current.resetTextTracks();
      }
    );
    return () => {
      capEnabledUnsub();
    };
  }, [subscribe, videoRef]);

  useEffect(() => {
    if (src) {
      //debugger;
      videoRef.current.load();
    }
  }, [src, videoRef]);

  useEffect(() => {
    videoRef.current.resetTextTracks();
  }, [descriptionsEnabled, captionsEnabled, videoRef]);

  const VideoElement = (
    <video
      style={{ backgroundColor: "white" }}
      ref={videoRef}
      className={"interface-video-player"}
      src={src}
      crossOrigin='anonymous'
      preload={"auto"}
      playsInline={1}
      webkit-playsinline={1}
      disablePictureInPicture
      controlsList={"nodownload"}
      autoPlay={1}
      poster={loaderGifs}
      onLoad={(event) => {
        //console.log("onLoad ----------------------");
        const nativeEvent = event;
        videoRef.current.initializeTextOverlayTracks();
        // eslint-disable-next-line no-restricted-globals
        onload(nativeEvent);
      }}
      onCanPlayThrough={onCanPlayThrough}
      onSeeked={onSeeked}
      onSeeking={onSeeking}
      onPlayCapture={onPlayCapture}
      onPlay={onPlay}
      onPauseCapture={onPauseCapture}
      onPause={onPause}
      {...videoProps}
      aria-hidden={"true"}
      /*controls*/
      loop={0}
    >
      {props.children}
      {captionsEnabled && (<track
          kind='captions'
          id={"PowerUpCaptions"}
          srcLang='en'
          label='PowerUpCaptions'
          { ...tracks.captions }
        />)}

        <track
          kind='descriptions'
          id={"PowerUpADs"}
          srcLang='en'
          label='PowerUpADs'
          {...tracks.descriptions}
        />
      {tracks.textOverlays && (
        <track
          kind='metadata'
          id={"TextOverlayTrack"}
          label='TextOverlayTrack'
          {...tracks.textOverlays}
        />
      )}
    </video>
  );

  return VideoElement;
}

InterfacePlayer.defaultProps = {
  src: null,
  tracks: {},
  onCanPlayThrough: () => {},
  onSeeked: () => {},
  onPlay: () => {},
  onPause: () => {},
  onLoad: () => {},
};

InterfacePlayer.propTypes = {
  videoRef: PropTypes.object,
  src: PropTypes.string,
  tracks: PropTypes.object,
  onCanPlayThrough: PropTypes.func,
  onSeeked: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onLoad: PropTypes.func,
};

export default InterfacePlayer;
