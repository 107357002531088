/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
export type MMPArray = [number, number, number];

function arrayToMMP(arr: RegExpMatchArray | null, defaultValue: MMPArray, position: number = 2): String {
  const newArray = arr !== null ? arr.slice(1).map((str) => parseInt(str, 10)) as MMPArray : defaultValue
  return newArray
    .concat(Array(3).fill(0))
    .slice(0, 3)
    .map((v) => (isNaN(v) ? 0 : v)).slice(0, position).join('.') as String;
}

function compareVersions(mmp: String, test: String, operator?: String): boolean {
    switch (operator) {
      case '<':
        return mmp < test
      case '>':
        return mmp > test
      case '=':
        return mmp === test
      case '<=':
        return mmp <= test
      case '>=':
        return mmp >= test
      default:
        return mmp >= test
      }
}

export const isIOS = (inputTestVersion: number | string = 0, operator?: string, position: number = 2) => {
  const mmpArr: MMPArray = [12, 0, 0];
  let newMmp: String = '12.0.0'
  const testArray = inputTestVersion.toString().split('.').concat(Array(3).fill(0)).slice(0,position).join('.')

  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const appVersion = navigator.appVersion; /*supports iOS 2.0 and later: <https://bit.ly/TJjs1V>*/
    // const appVersion = "5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1";
    const v = appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    newMmp = arrayToMMP(v, mmpArr, position);
  } else if (
    navigator.userAgent.includes('Mac') &&
    navigator.userAgent.includes('Safari/')
  ) {
    const arr = navigator.userAgent.replace(/.*Version\/([\d\.]+).*/, '$1');
    newMmp = arr.split('.').concat(Array(3).fill(0)).slice(0,position).join('.'); /*returns version number*/
  }

  return (
    ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) && /* iPad on iOS 13 detection */
      compareVersions(newMmp, testArray, operator)
  );
};

export default isIOS;