import * as Tone from "tone";
import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useStore } from "@tmsw/powerups.store";
import { useTranslate } from "@tmsw/powerups.intl.legacy.hooks.use-translate";
// import { LanguageSwitcher } from '@tmsw/powerups.intl.components.language-switcher';
// import { BrandCredit } from '@tmsw/powerups.ui.brand-credit';
import { TestingNav } from "@tmsw/powerups.debug.testing-nav";
import { useViewport } from "@tmsw/powerups.hooks.use-viewport";
import { PageTitleAnnouncer } from "@tmsw/powerups.ui.page-title-announcer";
import { GlobalInterrupter } from "@tmsw/powerups.ui.global-interrupter";
import { NavBar } from "@tmsw/powerups.ui.nav-bar";
import { SettingsDialog } from "@tmsw/powerups.ui.dialogs.settings";
import { isInPortrait } from "@tmsw/powerups.utils.screen-info";
import { HiddenH1 } from "@tmsw/powerups.ui.a11y.hidden-h1";
import { usePageConfig } from "@tmsw/powerups.config.page.provider";
// import BackButton from "components/BackButton";
import SkipButton from "../SkipButton/SkipButton";
import PauseButton from "../PauseButton/PauseButton";
// import VIE from "@tmsw/powerups.vie.player";
import VIE from "../Templates/VIE";
import OrientationChangeReminder from "../OrientationChangeReminder";
import { getDisabledPageIDs } from "../../hooks/usePauseScreenDisabled";
import "./GameWrapper.css";
import { PauseGeneral } from "../../pages/Pause/PauseGeneral";
import GAME_PAGES from "../../constants/GAME_PAGES";
import useResetFocus from "../../hooks/useResetFocus";
import SettingsButtonGradient from "../SettingsButtonGradient/SettingsButtonGradient";
// import BackButtonMusic from '../../pages/GamePlay/BackButtonMusic';
import { useVieConfig } from "@tmsw/powerups.vie.hooks.use-vie-config";
import { useReactAudioContext } from "@tmsw/powerups.audio.provider";
import { AudioDescriptions } from "../AudioDescriptions/AudioDescriptions";

const GameWrapper = (props) => {
  const translate = useTranslate();
  const inPortrait = useStore((state) => state.inPortrait);
  const setInPortrait = useStore((state) => state.setInPortrait);
  const play = useStore((state) => state.play);
  const setPlay = useStore(useCallback((state) => state.setPlay, []));
  const isPauseEnabled = useStore((state) => state.isPauseEnabled);
  const isPauseMenuDisplayed = useStore((state) => state.isPauseMenuDisplayed);
  const isPauseInterrupterDisplayed = useStore(
    (state) => state.isPauseInterrupterDisplayed
  );
  const setIsPauseInterrupterDisplayed = useStore(
    (state) => state.setIsPauseInterrupterDisplayed
  );
  const setIsPauseEnabled = useStore((state) => state.setIsPauseEnabled);
  const setUserId = useStore((state) => state.setUserId);
  const page = useStore((state) => state.currentPage);
  const setIsPauseBtnDisplayed = useStore(
    (state) => state.setIsPauseBtnDisplayed
  );
  const documentVisibilityState = useStore(
    (state) => state.documentVisibilityState
  );
  const setDocumentVisibilityState = useStore(
    useCallback((state) => state.setDocumentVisibilityState, [])
  );
  const currentVIEConfig = useStore((state) => state.currentVIEConfig);
  const showSettingsPanel = useStore((state) => state.showSettingsPanel);
  const playerConfig = translate("VIE"); // { state.setSkipTarget }
  const currentCueRef = useRef("");
  const setToyOnScreenLayout = useStore((state) => state.setToyOnScreenLayout);
  const [showPGwarning, setShowPGwarning] = useState(false);
  const currentCueMetadata = useStore((state) => state.currentCueMetadata);
  const parentNodeDisclaimer = "DISCLAIMER";
  const pgLogoImg = translate("pgLogoImg", parentNodeDisclaimer);
  const pgLogoAlt = translate("pgLogoAlt", parentNodeDisclaimer);
  const setIsPauseMenuDisplayed = useStore(
    useCallback((state) => state.setIsPauseMenuDisplayed, [])
  );
  const tabBack = () => {
    setIsVisible(false);
    setIsPauseEnabled(false);
    setIsPauseMenuDisplayed(false);
  };

  useEffect(() => {
    isPauseEnabled ? AudioDescriptions.pause() : AudioDescriptions.resume();
  }, [isPauseEnabled]);

  useEffect(() => {
    console.log("IS PAUSE ENABLED : " + isPauseEnabled);
    console.log("IS PAUSE MENU DISPLAYED : " + isPauseMenuDisplayed);
  }, [isPauseMenuDisplayed, isPauseEnabled]);
  useEffect(() => {
    if (isPauseMenuDisplayed) {
      // hide the Pause button when PauseMenu is displayed
      setIsPauseBtnDisplayed(false);
    }
  }, [isPauseMenuDisplayed, setIsPauseBtnDisplayed]);
  // const [isNavBarTopPositioned, setIsNavBarTopPositioned] = useState(true);

  // useEffect(() => {
  //   if (
  //     currentCueMetadata?.id == "END_LOOP" ||
  //     page === GAME_PAGES.DISCLAIMER
  //   ) {
  //     setShowPGwarning(true);
  //   } else {
  //     setShowPGwarning(false);
  //   }
  // }, [currentCueMetadata, page]);

  // check if toy is on screen
  // useEffect(() => {
  //   const navElement = document.querySelector("nav");
  //   const pauseAndSkipButtons = document.querySelector("#nav-bar-row");
  //   const body = document.querySelector("body");

  //   if (page === GAME_PAGES.DISCLAIMER || page === GAME_PAGES.SPLASH) {
  //     setToyOnScreenLayout(false);
  //     body?.setAttribute("data-layout", "default");
  //     navElement?.classList.remove("middle-positioned");
  //     pauseAndSkipButtons?.classList.add("showNavAtTop");
  //   } else {
  //     setToyOnScreenLayout(true);
  //     body?.setAttribute("data-layout", "toy-on-screen");
  //     navElement?.classList.add("middle-positioned");
  //     pauseAndSkipButtons?.classList.remove("showNavAtTop");
  //   }
  // }, [page, showSettingsPanel, isPauseEnabled, inPortrait]);

  // console.log('currentVIEConfig', currentVIEConfig);

  // initialize viewport hook
  const viewport = useViewport();

  const { goToPage, goToNextPage, goToPrevPage } = useVieConfig();
  const { getPageKey } = usePageConfig();
  // const { audioCtxStarted, getContext } = useReactAudioContext();

  const pageKey = getPageKey(page);
  const pageConfig = translate(pageKey);

  let children = Children.toArray(props.children);
  const parentNode = "INTERRUPTER_ORIENTATION";
  const interrupterOrientationCopy = translate(parentNode);
  const parentNodePause = "PAUSE_MENU";

  const interrupterPauseCopy = translate(parentNodePause);

  const byLine = translate("text", "BYLINE");

  const pauseScreenDisabledPages = interrupterPauseCopy.disablePauseScreenOn;
  const pauseScreenDisabledPagesID = [];

  pauseScreenDisabledPages.forEach((pageName) => {
    if (pageName === "DISCLAIMER") {
      pauseScreenDisabledPagesID.push(parseInt(0));
    } else if (pageName === "SPLASH") {
      pauseScreenDisabledPagesID.push(parseInt(1));
    } else if (pageName === "CAMERA_ACCESS") {
      pauseScreenDisabledPagesID.push(parseInt(2));
    } else if (pageName === "TOY_SCAN") {
      pauseScreenDisabledPagesID.push(parseInt(3));
    } else if (pageName === "CHARACTER_SELECTION") {
      pauseScreenDisabledPagesID.push(parseInt(4));
    } else if (pageName === "GAME_PLAY") {
      pauseScreenDisabledPagesID.push(parseInt(5));
    } else if (pageName === "END_GAME") {
      pauseScreenDisabledPagesID.push(parseInt(6));
    }
  });

  // in use to show interrupter on orientation change (based on json)
  const [captureLockOrientation, setCaptureLockOrientation] = useState();
  const [orientationFetched, setOrientationFetched] = useState(false);

  // in use for showing pause screen on interrupter unmount
  const [isInterrupterMounted, setIsInterrupterMounted] = useState(true);

  useResetFocus([showSettingsPanel, inPortrait, isPauseEnabled]);

  // const orientationHandler = useCallback(
  //   (evt) => {
  //     const portraitMode = isInPortrait();
  //     setInPortrait(portraitMode);

  //     //Pause all video
  //     if (!portraitMode) {
  //       //debugger;
  //       setPlay(false);
  //     } else {
  //       if (!showSettingsPanel && !isPauseEnabled) {
  //         // when Settings Panel is not open & video is not previously paused
  //         setPlay(true);
  //       }
  //     }
  //   },
  //   [setPlay, setInPortrait, showSettingsPanel, isPauseEnabled]
  // );
  const alertMountedWarning = () => {
    if (!pauseScreenDisabledPagesID.includes(page)) {
      setIsInterrupterMounted(true);
    }
  };
  const orientationHandler = useCallback(
    (evt) => {
      if (interrupterOrientationCopy.forcedOrientation === "portrait") {
        setCaptureLockOrientation(true);
        setOrientationFetched(true);
      } else {
        setCaptureLockOrientation(false);
        setOrientationFetched(true);
      }
      const portraitMode = isInPortrait();
      setInPortrait(portraitMode);

      //Pause all video
      if (!portraitMode) {
        //debugger;
        setPlay(false);
      } else {
        if (!showSettingsPanel && !isPauseEnabled) {
          // when Settings Panel is not open & video is not previously paused
          setPlay(true);
        }
      }
    },
    [page, setPlay, setInPortrait, showSettingsPanel, isPauseEnabled]
  );
  const settingsPanelPlayStateOnOpenRef = useRef(false);

  useEffect(() => {
    /**
     * @todo Restore the previous video player state instead of forcing play
     * @returns {void}
     */
    const setPlayability = () => {
      if (isPauseEnabled) return; // if video is already paused before settings panel opened, then avoid calling setPlay()
      if (showSettingsPanel && play === true) {
        settingsPanelPlayStateOnOpenRef.current = true;
        // when Settings Panel is open
        console.trace("setPlayability :: setPlay(false)");
        setPlay(false);
      } else if (
        !showSettingsPanel &&
        play === false &&
        settingsPanelPlayStateOnOpenRef.current
      ) {
        console.trace("setPlayability :: setPlay(true)");
        setPlay(true);
      }
    };
    setPlayability();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [showSettingsPanel, isPauseEnabled]);

  // const handleVisibilityChange = useCallback(() => {
  //   if (!audioCtxStarted) return;
  //   const audioCtxState = Tone.getContext().state;
  //   const audioCtxSuspended = audioCtxState === "suspended";
  //   if (document.visibilityState === documentVisibilityState) return; // nothing to do
  //   setDocumentVisibilityState(document.visibilityState);
  //   if (document.visibilityState === "hidden") {
  //     //debugger;
  //     setPlay(false);
  //     if (audioCtxSuspended) return;
  //     Tone.Transport.stop();
  //   } else {
  //     if (!showSettingsPanel && !isPauseEnabled && isInPortrait()) {
  //       // when Settings Panel is not open & video is not previously paused
  //       setPlay(true);
  //       if (audioCtxSuspended) return;
  //       Tone.Transport.start();
  //     }
  //   }
  // }, [setPlay, showSettingsPanel, isPauseEnabled]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      setIsPauseInterrupterDisplayed(true);
      setIsPauseEnabled(true);
    }
  }, [setIsPauseInterrupterDisplayed, setIsPauseEnabled]);

  useEffect(() => {
    isPauseEnabled || isPauseInterrupterDisplayed || isPauseMenuDisplayed
      ? AudioDescriptions.cancel()
      : AudioDescriptions.resume();
  }, [isPauseEnabled, isPauseInterrupterDisplayed, isPauseMenuDisplayed]);

  useEffect(() => {
    window.addEventListener("resize", orientationHandler);
    let hasChangeListener = false;
    if (window.screen.hasOwnProperty("orientation")) {
      hasChangeListener = true;
      window.screen.orientation.addEventListener("change", orientationHandler);
    }
    setInPortrait(isInPortrait());

    return () => {
      window.removeEventListener("resize", orientationHandler);
      if (hasChangeListener) {
        window.screen.orientation.removeEventListener(
          "change",
          orientationHandler
        );
      }
    };
  }, [orientationHandler, handleVisibilityChange, setInPortrait]);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!pauseScreenDisabledPagesID.includes(page)) {
          setIsVisible(true);
          setIsPauseEnabled(true);
          setPlay(false);
        }
      } else {
        setIsVisible(false);
        window.dispatchEvent(
          new CustomEvent("pausePC", {
            detail: {
              showPauseButton: false,
              isPauseEnabled: true,
            },
          })
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    orientationHandler();
    handleVisibilityChange();
  }, [orientationHandler, handleVisibilityChange]);

  useEffect(() => {
    setUserId(new URLSearchParams(window.location.search).get("userId"));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (isPauseMenuDisplayed) {
      // hide the Pause button when PauseMenu is displayed
      setIsPauseBtnDisplayed(false);
    }
  }, [isPauseMenuDisplayed, setIsPauseBtnDisplayed]);

  const mainContentHidden =
    !inPortrait || showSettingsPanel || isPauseMenuDisplayed || isPauseEnabled;

  // console.log("currentVIEConfig", currentVIEConfig);

  return (
    <>
      <main
        className={`game-wrapper ${pageKey} ${currentCueRef.current}`}
        key={"game-wrapper"}
        style={{
          height: `${viewport.height}px`,
          overflow: "hidden",
        }}
        // aria-hidden={isPauseMenuDisplayed || !inPortrait ? "true" : "false"}
      >
        <PageTitleAnnouncer />
        <SettingsButtonGradient />
        {inPortrait && (
          <NavBar
            hidden={mainContentHidden}
            slotConfig={{
              root: (
                <>
                  <SettingsDialog />
                </>
              ),
              row: (
                <>
                  {/* {!isPauseEnabled && !page === GAME_PAGES.GAME_PLAY && ( */}
                  <HiddenH1 />
                  {/* )} */}
                  {/* <BackButton onClick={(evt) => goToPrevPage()} /> */}
                  <PauseButton />
                  <SkipButton />
                </>
              ),
            }}
          />
        )}
        <div
          className='gamewrapper-inner'
          hidden={mainContentHidden}
          aria-hidden={mainContentHidden}
          style={{
            // height: "100%",
            // width: "100%",
            display: mainContentHidden ? "none" : "block",
          }}
        >
          {children
            .map((child) => {
              const pageProps = {
                ...child.props,
                goToNextPage: goToNextPage,
                goToPrevPage: goToPrevPage,
                goToPage: goToPage,
                pageConfig: pageConfig,
              };
              return cloneElement(child, pageProps);
            })
            .find((child) => child.key === `.$${page}`)}

          <VIE
            InterfaceVideo={currentVIEConfig?.video}
            ToyVideo={currentVIEConfig?.toyVideo}
            Metadata={currentVIEConfig?.metadata}
            Captions={currentVIEConfig?.captions}
            Descriptions={currentVIEConfig?.descriptions}
            TextOverlays={currentVIEConfig?.legal}
            Templates={currentVIEConfig?.templates}
            PlayerConfig={playerConfig}
            Overlays={currentVIEConfig?.overlays}
            onEnded={currentVIEConfig?.onEnded || null}
            chapterOverride={currentVIEConfig?.skipChapter}
            setBeatsList={currentVIEConfig?.setBeatsList}
            Poster={currentVIEConfig?.Poster}
            hide={currentVIEConfig?.hide}
            onNextPage={() => goToNextPage()}
            onPrevPage={() => goToPrevPage()}
            onGoToPage={(v) => goToPage(v)}
            onCueChange={(v) => {
              currentCueRef.current = v[0].id;
            }}
            gameCondition={currentVIEConfig?.gameCondition}
          />
          {page === GAME_PAGES.DISCLAIMER && (
            <div className='copyright-text'>
              {showPGwarning && (
                <div className='pg-logo-wrapper'>
                  <img
                    src={pgLogoImg}
                    alt={pgLogoAlt}
                    className='splash-logo-img'
                  />
                </div>
              )}
            </div>
          )}

          <span
            style={{
              position: "fixed",
              bottom: ".5rem",
              right: "0",
              color: "#FFF",
              padding: "1vw",
              fontSize: "1.5rem",
              fontWeight: "400",
              textAlign: "center",
              fontFamily: "Speedee",
              display: page === 0 || page === 1 ? "block" : "none",
              visibility: isPauseEnabled ? "hidden" : "visible",
              "--shadow-offset": "clamp(1px, 0.2vmin, 3px)",
              textShadow:
                "var(--shadow-offset) var(--shadow-offset) var(--shadow-offset) #000000",
            }}
          >
            {byLine}
          </span>
        </div>

        {inPortrait && isPauseEnabled && !showSettingsPanel && (
          <PauseGeneral tabBack={tabBack} />
        )}
        {!inPortrait && (
          <OrientationChangeReminder
            alertMountedWarning={alertMountedWarning}
            alertUnMountedWarning={alertMountedWarning}
          />
        )}
        {/* <TestingNav
          pageIndex={page}
          pagesMap={pagesMap}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
        /> */}
        {/* <LanguageSwitcher hidden={mainContentHidden} aria-hidden={true} /> */}
      </main>
    </>
  );
};

export default GameWrapper;
