import type { FC, CSSProperties } from 'react';
import type { AoOverlayProps } from './types';
import AnimationOverlayItem from './AnimationOverlayItem';
import './animation-overlay.css';
import { useAoStateContext } from './context';

const Overlay:FC<AoOverlayProps> = (props) => {
  // const elementRef = useRef(null);
  const { animations } = useAoStateContext();

  const overlayStyle = {
    ...props.style,
  } as CSSProperties;

  return (<div className="animation-overlay" style={overlayStyle}>
    {animations.map(
        (item) => (<AnimationOverlayItem key={ item.hash } { ...item } />)
    )}
    </div>);

};

export declare type IAnimationOverlay = typeof Overlay;

export default Overlay;
