import { useRef, useCallback } from "react";
import { useStore, useStoreApi } from "@tmsw/powerups.store";
import { AudioDescriptions } from "../../../../AudioDescriptions/AudioDescriptions";

const useDescriptionsCueChange = (video) => {
  const lastDescRef = useRef(null);
  const descIdRef = useRef(0);

  const setCurrentDescription = useStore(
    (state) => state.setCurrentDescription
  );

  const onCueChange = useCallback(
    (event) => {
      if (video && !video.paused) {
          console.log("onCueChange", event);

      var descText = "";

      for (var j = 0; j < event.target.activeCues.length; j++) {
        descText += j
          ? `, ${event.target.activeCues[j].text}`
          : event.target.activeCues[j].text;
      }

      if (descText.length && lastDescRef.current !== descText) {
        console.log(`text: ${descText}`);

        setCurrentDescription(descText);

        var activeCue = event.target.activeCues[0];

        if (activeCue && activeCue.text) {
          AudioDescriptions.readDescription(activeCue);
        }

              descIdRef.current++;
              lastDescRef.current = descText;
          }
      }
    },
    [setCurrentDescription, video]
  );

  return onCueChange;
};

export default useDescriptionsCueChange;
